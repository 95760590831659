import { Capacitor } from '@capacitor/core';
import { SubscribeModalAndroid } from './android/SubscribeModalAndroid';
import { SubscribeModalIos } from './ios/SubscribeModalIos';
import React from 'react';
import { useRuntimeStore } from '../../initApp';

export const SubscriptionModal = () => {
  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );
  const isSubscriptionPopupVisible = useRuntimeStore(
    (s) => s.isSubscriptionPopupVisible
  );

  return (
    <>
      {isSubscriptionPopupVisible &&
        (Capacitor.getPlatform() === 'android' ? (
          <SubscribeModalAndroid close={toggleSubscriptionPopup} />
        ) : (
          <SubscribeModalIos close={toggleSubscriptionPopup} />
        ))}
    </>
  );
};

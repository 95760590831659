import { TTask } from '../types';
import { getFilledString } from './mixRealSentenceWordsWithFakeOnes';

export const fillForeignWithWords = (task: TTask | null): string => {
  if (!task) return '';

  const placeholders = mapCorrectIndexesToWords(task);

  return getFilledString(placeholders, task.foreign);
};

function mapCorrectIndexesToWords(task: TTask): string[] {
  return (task.correct || []).map((index) => task.random[index - 1]);
}

import { useEffect, useState } from 'react';
import { GlassfySku } from 'capacitor-plugin-glassfy';
import * as Sentry from '@sentry/react';
import { useShowCatForMsNew } from '../../../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
// import { Period, Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { RevenueCatFunctions } from '../../../RevenueCatFunctions';
import mock from './revenueCat.json';
import { mapper } from './mapper';
import { PERIODS, TPolakoOffering } from '../../../types';
// import { useAndroidBillingSetAccessToAllPermissions } from '../../../hooks/billing/useAndroidBillingSetAccessToAllPermissions';

type TProps = {
  close: () => void;
};

// @ts-ignore
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getText = (sku: TPolakoOffering | null) => {
  if (!sku) return '';

  const { period } = sku;

  return `${PERIODS[period as PERIODS]}`;
};

const getTextLine2 = (sku: TPolakoOffering | null) => {
  if (!sku) return '';

  return `${sku.price} ${sku.currencyCode}`;
};

export const useSubscribeModalAndroid = ({ close }: TProps) => {
  const { showCat } = useShowCatForMsNew();
  const [selected, setSelected] = useState<TPolakoOffering | null>(null);
  const [skus, setSkus] = useState<TPolakoOffering[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successResult, setSuccessResult] = useState(false);

  const { setBillingAccessToAllPermissions } =
    useAndroidBillingSetAccessToAllPermissions();

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }

    // eslint-disable-next-line no-inner-declarations
    async function getOfferings() {
      try {
        setIsLoading(true);
        const offerings = await RevenueCatFunctions.getOfferings();
        // const offerings = mock;
        const mappedSkus = mapper(offerings);
        console.log('ANDROID offerings', mappedSkus);
        setSkus(mappedSkus);
        setSelected(mappedSkus[0]);

        setIsLoading(false);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        showCat({
          text: 'Не получилось загрузить цены, сорри.',
          textSub: 'Какая-то фигня :(',
        });
        setIsLoading(false);
      }
    }

    getOfferings();
  }, []);

  const buy = async () => {
    if (!selected) return;

    try {
      setIsLoading(true);

      const res = await RevenueCatFunctions.getOfferings();

      console.log({ res });

      try {
        const purchaseResult = await Purchases.purchasePackage({
          aPackage: res.current?.monthly,
        });

        console.log({ purchaseResult });

        const resSetAccess = await setBillingAccessToAllPermissions(
          purchaseResult.customerInfo
        );

        if (resSetAccess === false) {
          throw new Error('setBillingAccessToAllPermissions failed');
        }
      } catch (error: any) {
        console.log('error', error);
        showCat({
          text: 'Не получилось оплатить',
          textSub: 'Какая-то фигня :(',
        });
      }

      setSuccessResult(true);
      setIsLoading(false);
      close();
    } catch (e) {
      showCat({
        text: 'Не получилось оплатить',
        textSub: 'Какая-то фигня :(',
      });

      console.error(e);
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  return {
    skus,
    isLoading,
    successResult,
    selected,
    setSelected,
    buy,
    getText,
    getTextLine2,
  };
};

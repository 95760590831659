import { getRandomElementNotInB } from './getRandomElementNotInB';
import { TTask, TTaskId } from '../types';

// export const getNextItem = <T>(allData: T[], ids: number[]): T => {
//   for (let i = 0; i < allData.length; i++) {
//     if (!ids.includes(allData[i].id)) {
//       return allData[i];
//     }
//   }
//
//   // ! тут айдишники а не индексы массива надо уже , возможно поэтому не открывается новый урок
//   // return getRandomElementNotInB(allData, ids);
// };

const tryToFindNextTaskWhichWasNotSelectedYet = (
  allTasks: TTask[],
  alreadySelectedTaskIds: TTaskId[]
) => {
  for (const task of allTasks) {
    if (!alreadySelectedTaskIds.includes(task.id)) {
      return task;
    }
  }

  return null;
};

const getRandomTask = (allTasks: TTask[]): TTask => {
  const randomIndex = Math.floor(Math.random() * allTasks.length);
  return allTasks[randomIndex];
};

export const getNextTaskWhichWasNotSelectedYet = (
  allTasks: TTask[],
  alreadySelectedTaskIds: TTaskId[]
): TTask => {
  // Определите список заданий, которые еще не были выбраны
  const notSelectedTasks = allTasks.filter(
    (task) => !alreadySelectedTaskIds.includes(task.id)
  );

  const tasksToChooseFrom =
    notSelectedTasks.length > 0 ? notSelectedTasks : allTasks;

  // Верните случайное задание из списка
  const randomIndex = Math.floor(Math.random() * tasksToChooseFrom.length);
  return tasksToChooseFrom[randomIndex];
  //
  // const firstNextTask = tryToFindNextTaskWhichWasNotSelectedYet(
  //   allTasks,
  //   alreadySelectedTaskIds
  // );
  //
  // return firstNextTask || getRandomTask(allTasks);
};

import { useShowCatForMsNew } from '../../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
import { useState } from 'react';
import { LocalNotifications } from '@capacitor/local-notifications';
import { useRuntimeStore } from '../../initApp';
import * as Sentry from '@sentry/react';
import { TChangeEventHandler } from '../../types';
import { Analytics } from '../../analytics';
import { useThink } from '../../hooks/useThink';

export const useLocalNotifications = () => {
  const { showCat } = useShowCatForMsNew();

  const [time, setTime] = useState<string>('');
  const [isNotificationsOn, setIsNotificationsOn] = useState(false);

  const { getLocalPushNotificationsTime, saveLocalPushNotificationsTime } =
    useRuntimeStore();

  const { showThinking, hideThinking } = useThink();

  const setRepeatedNotificationsTime = async () => {
    await scheduleNativeNotification();
  };

  const showNoPermissionsCat = () => {
    showCat({
      text: `Не получилось 😕`,
      textSub: 'Надо дать разрешение в настройках телефона',
    });
  };

  async function scheduleNativeNotification() {
    const permissions = await LocalNotifications.checkPermissions();

    if (permissions.display !== 'granted') {
      const requestedPermissions =
        await LocalNotifications.requestPermissions();

      if (requestedPermissions.display !== 'granted') {
        showNoPermissionsCat();
        return;
      }
    }

    const pending = await LocalNotifications.getPending();

    if (pending.notifications.length > 0) {
      await LocalNotifications.cancel(pending);
    }

    try {
      if (!time) {
        showCat({
          text: `Не установил время`,
          textSub: 'Можно потом в настройках',
        });
        return;
      }
      const [hour, minute] = time.split(':').map(Number);

      await LocalNotifications.schedule({
        notifications: [
          {
            title: 'Здорова, это я, кот',
            body: 'Тебе бы позаниматься сербским минут 5',
            id: 1,
            schedule: {
              repeats: true,
              on: {
                hour,
                minute,
              },
            },
            actionTypeId: 'regularNotification',
            extra: null,
          },
        ],
      });

      await saveLocalPushNotificationsTime(time);
      showCat({
        text: `Отлично! Поставили на ${time}`,
        textSub: 'Если что, изменить можно в настройках',
      });
      Analytics.setNotifications();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function switchOffNativeNotification() {
    try {
      const pending = await LocalNotifications.getPending();

      if (pending.notifications.length > 0) {
        await LocalNotifications.cancel(pending);
      }

      await saveLocalPushNotificationsTime(null);
      setTime('');

      showCat({
        text: `Договорились!`,
        textSub: `Убрал уведомления`,
      });
    } catch (e) {
      showCat({
        text: `Какая-то ошибка при сбросе, сорри!`,
      });
      console.error(e);
      Sentry.captureException(e);
    }
  }

  const getIsNotificationsPermissionsGranted = async () => {
    const permissions = await LocalNotifications.checkPermissions();

    return permissions.display === 'granted';
  };

  const onNotificationsCheckboxChange: TChangeEventHandler = async (e) => {
    const isSwitchedOn = e.target.checked;

    if (!isSwitchedOn) {
      switchOffNativeNotification();
      setIsNotificationsOn(isSwitchedOn);
      Analytics.cancelNotificationsFromSettings();
      return;
    }

    const permissions = await LocalNotifications.checkPermissions();
    if (permissions.display === 'denied') {
      showNoPermissionsCat();
      return;
    }

    setIsNotificationsOn(isSwitchedOn);
    if (time) {
      await scheduleNativeNotification();
    }
  };

  const getNotificationsData = async function () {
    try {
      await showThinking();
      const notificationsTime = await getLocalPushNotificationsTime();
      const isGranted = await getIsNotificationsPermissionsGranted();

      if (!isGranted && notificationsTime) {
        setIsNotificationsOn(false);
        await saveLocalPushNotificationsTime(null);
      } else if (notificationsTime) {
        setIsNotificationsOn(true);
        setTime(notificationsTime);
      }

      await hideThinking();
      Analytics.setCurrentScreen('Settings');
    } catch (e) {
      await hideThinking();
    }
  };

  const initNotificationsOnSettingsPage = async () => {
    getNotificationsData();

    async function initNotificationsPermissions() {
      const isGranted = await getIsNotificationsPermissionsGranted();

      if (!isGranted && isNotificationsOn) {
        setIsNotificationsOn(false);
        saveLocalPushNotificationsTime(null);
      }
    }

    initNotificationsPermissions();
  };

  return {
    time,
    setTime,
    setRepeatedNotificationsTime,
    showNoPermissionsCat,
    getIsNotificationsPermissionsGranted,
    initNotificationsOnSettingsPage,
    onNotificationsCheckboxChange,
    isNotificationsOn,
  };
};

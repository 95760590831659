import { PageWrapper } from '../../../components/PageWrapper';
import { Typo } from '../../../components/Typo';
import TextWithSubLogo from '../../../assets/images/text-with-sub.svg';
import CatFace from '../../../assets/images/cat-face.svg';
import styled from 'styled-components';
import { SWrapperWithDots } from '../../../components/Modal/Modal';
import React from 'react';

import { SKletka } from '../../../components/SubscribeModal/android/SubscribeModalAndroid';
import { SubscriptionContent } from '../../../components/SubscribeModal/components/SubscriptionContent';
import { Button } from '../../../components/Button';
import { Clipboard } from '@capacitor/clipboard';
import { useShowCatForMsNew } from '../../Game/components/EncouragingCat2/useShowCatForMsNew';
import { FlowerPoint } from '../../../components/FlowerPoint';

export const Pay = () => {
  const { showCat } = useShowCatForMsNew();

  return (
    <PageWrapper>
      <SContent1>
        <SLogoWrapper>
          <TextWithSubLogo width="70%"></TextWithSubLogo>
        </SLogoWrapper>
      </SContent1>

      <SContent2>
        <CatFace width="50%"></CatFace>
      </SContent2>

      <SWrapperWithDotsStyled>
        <SKletka />
        <Typo.Header0 $marginBottom={'30px'}>Простите-извините</Typo.Header0>

        <Typo.Text1 $marginBottom={'30px'}>
          В ситуации, когда не все могут оплатить через Apple, приходится
          прибегать к такому вот кринжу 🤯
        </Typo.Text1>

        <Typo.Header0 $marginBottom={'30px'}>Что делать</Typo.Header0>

        <FlowerPoint>
          Переводите на карту 5536 9141 2629 8961
          <br />
          <Button
            text={'Скопировать номер карты'}
            onClick={async () => {
              try {
                await Clipboard.write({
                  string: '5536914126298961',
                });
                showCat({
                  text: 'Скопировал!',
                });
              } catch (e) {
                showCat({
                  text: 'Не получилось скопировать(',
                  textSub: 'Скопируй плз вручную',
                });
              }
            }}
            square={false}
          />
          <br />
          <br />
          <SPriceText>— 999₽ за доступ на полгода </SPriceText>
          <SPriceText>— 1499₽ за доступ на год </SPriceText>
          <SPriceText>
            — 1999₽ за постоянный доступ{' '}
            {/*<SSmallTextSpan>(ну т.е. пока я жив-здоров)</SSmallTextSpan>*/}
          </SPriceText>
          <br />
        </FlowerPoint>

        <FlowerPoint>
          Присылаете мне в телеграм*{' '}
          <a href={'https://t.me/polakoapp'}>@polakoapp</a>:
          <br />
          <br />{' '}
          <SPriceText>
            — ваш ID (раздел “Настройки” в Polako, самый низ){' '}
          </SPriceText>
          <SPriceText>— скриншот платежа </SPriceText>
          <SSmallText>* или на почту polakoapp1@gmail.com</SSmallText>
          <br />
        </FlowerPoint>

        <FlowerPoint>
          Я активирую ваш акаунт
          <br />
          <SSmallText>
            (как только увижу сообщение, обычно в течение минут 5-10, если это
            не ночь)
          </SSmallText>
        </FlowerPoint>
        <br />
        <br />

        <SubscriptionContentStyled
          title={
            <>
              Hvala na razumevanju! <br />
              (Спасибо за понимание) ❤️
            </>
          }
        >
          <SGraySmallText>
            Ваша оплата пойдет на развитие проекта, на подарки жене и на еду
            моей кошке
          </SGraySmallText>
        </SubscriptionContentStyled>

        <br />

        <span className="bottom-dots"></span>
      </SWrapperWithDotsStyled>
    </PageWrapper>
  );
};

const SubscriptionContentStyled = styled(SubscriptionContent)`
  width: 100%;
`;

const SGraySmallText = styled.div`
  font-size: 10px;
  color: var(--polako-gray);
  line-height: 1.2;
`;

const SPriceText = styled.div`
  font-size: 12px;
  line-height: 1.8;
`;

const SWrapperWithDotsStyled = styled(SWrapperWithDots)`
  background: var(--polako-yellow-dark, #f8b91d);
`;

const SLogoWrapper = styled.div`
  width: 100%;
  margin-block-end: 20px;
`;

const SContent1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
`;

const SContent2 = styled.div`
  flex: 1;
  height: 160px;
  display: flex;
  justify-content: center;
  margin-block-end: 30px;
`;

const SSmallText = styled.div`
  font-size: 8px;
`;

const SSmallTextSpan = styled.span`
  font-size: 8px;
`;

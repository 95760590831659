import styled from 'styled-components';
import { FC } from 'react';

interface ProfileCardProps {
  imageUrl: string;
  name: string;
  position: string;
}

export const ProfileCard: FC<ProfileCardProps> = ({
  imageUrl,
  name,
  position,
}) => {
  return (
    <ProfileContainer>
      <ProfileImage imageUrl={imageUrl} />
      <ProfileInfo>
        <ProfileName>{name}</ProfileName>
        <ProfilePosition>{position}</ProfilePosition>
      </ProfileInfo>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--polako-black);
`;

const ProfileImage = styled.div<{ imageUrl: string }>`
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--polako-black);
  box-shadow: var(--polako-shadow-thick);
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  margin-right: 17px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProfileName = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const ProfilePosition = styled.span`
  font-size: 11px;
  font-weight: 600;
`;

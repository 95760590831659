import { PurchasesOfferings } from '@revenuecat/purchases-capacitor';
import { TPolakoOffering } from '../../../types';

export const mapper = (
  offerings: PurchasesOfferings
): TPolakoOffering[] | undefined => {
  return offerings.current?.availablePackages.map((rcPackage) => {
    const product = rcPackage.product;
    return {
      id: product.identifier,
      title: product.title,
      description: product.description,
      price: product.priceString,
      currencyCode: product.currencyCode,
      period: product.subscriptionPeriod,
    };
  });
};

import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ButtonSimple } from '../ButtonSimple';

type TProps = HTMLAttributes<HTMLButtonElement> & {
  text: string;
  textLine2: string;
  extraText?: string;
  extraText2?: string;
  selected?: boolean;
  onClick?: () => void;
};

export const BuyRadio: FC<TProps> = ({
  text,
  textLine2,
  extraText,
  extraText2,
  selected,
  onClick,
  ...props
}) => {
  return (
    <ButtonStyled
      type="button"
      onClick={onClick}
      $selected={Boolean(selected)}
      {...props}
    >
      <SSpan>
        <SRadio selected={selected} />
        <SRight>
          <span>{text}</span>
          <span>{textLine2}</span>
          {extraText && <SExtra>{extraText}</SExtra>}
          {extraText2 && <SExtra>{extraText2}</SExtra>}
        </SRight>
      </SSpan>
    </ButtonStyled>
  );
};

const SRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 5px;
`;

const SExtra = styled.span`
  font-size: 8px;
  color: var(--polako-gray);
  margin-top: 5px;
`;

const ButtonStyled = styled(ButtonSimple)<{ $selected: boolean }>`
  height: auto;
  font-weight: 600;
  font-size: 12px;
  box-shadow: var(--polako-shadow);
  background: ${(props) =>
    props.$selected ? 'var(--polako-green)' : 'var(--polako-white)'};
  padding: 5px;
  border: 1px solid var(--polako-black);
  position: relative;
  cursor: default;
  color: var(--polako-black);

  &:active {
    box-shadow: none;
    top: 4px;
    left: 4px;
  }
`;

const SSpan = styled.span`
  display: flex;
  align-items: center;
`;

const SRadio = styled.div<{ selected?: boolean }>`
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--polako-black);
  box-shadow: var(--polako-shadow-minimal);
  margin-right: 10px;

  ${(props) =>
    props.selected &&
    css`
      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: var(--polako-black);
        position: relative;
        left: 3px;
        top: 3px;
      }
    `}
`;

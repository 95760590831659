import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { DEFAULT_LESSON_FIRST } from '../../constants';
import { Analytics } from '../../analytics';

export const createUserDoc = async (uid: string) => {
  const reference = `users/${uid}`;
  const { snapshot: userSnapshot } = await FirebaseFirestore.getDocument({
    reference,
  });

  if (!userSnapshot.data) {
    console.log('Документ НЕ существует!');
    Analytics.setSignUp();
    await FirebaseFirestore.setDocument({
      reference,
      data: {
        visitDates: [],
      },
      merge: true,
    });
  }
};

const createLessonIfNotExists = async (
  collectionPath: string,
  lessonPath: string
) => {
  const reference = `${collectionPath}/${lessonPath}`;
  const { snapshot: documentSnapshot } = await FirebaseFirestore.getDocument({
    reference,
  });

  if (!documentSnapshot.data) {
    console.log(`No such document ${reference}`);
    return await FirebaseFirestore.setDocument({
      reference,
      data: { ...DEFAULT_LESSON_FIRST },
    });
  }
};

export const setInitialLessons = async (userId: string) => {
  try {
    await createLessonIfNotExists(`users/${userId}/lessons`, 'lesson_0');
    await createLessonIfNotExists(`users/${userId}/lessons`, 'lesson_1');

    console.log('Стартовые уроки инициализированы');
  } catch (e) {
    console.error('Ошибка при инициализации стартовых уроков', e);
  }
};

export const setInitialData = async (userId: string) => {
  await createUserDoc(userId);
  await setInitialLessons(userId);
};

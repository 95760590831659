import { SIconAndText, SIconDesc, SIconText } from '../../../components/styles';
import { IonIcon, useIonViewDidEnter } from '@ionic/react';
import fireIcon from '../../../assets/icons/fire.svg?inline';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { useRuntimeStore } from '../../../initApp';

export const CorrectlySolved = () => {
  const [solved, setSolved] = useState(0);
  const { getCorrectlySolvedCount } = useRuntimeStore();

  useIonViewDidEnter(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [getCorrectlySolvedCount]);

  const history = useHistory();
  const handleButtonClick = () => {
    history.replace('/stat');
  };

  const getData = async function () {
    const newSolved = await getCorrectlySolvedCount();
    setSolved(newSolved);
  };

  return (
    <SIconAndTextStyled>
      <ButtonStyled
        color="yellow"
        size="m"
        square={false}
        onClick={handleButtonClick}
      >
        <IonIcon slot="start" icon={fireIcon}></IonIcon>
        <SIconText>{solved}</SIconText>
      </ButtonStyled>

      <SIconDesc>
        <SIconText>
          правильно <br />
          решенных
          <br />
          за все время
        </SIconText>
      </SIconDesc>
    </SIconAndTextStyled>
  );
};

const SIconAndTextStyled = styled(SIconAndText)`
  min-width: 150px;
`;

const ButtonStyled = styled(Button)`
  height: 28px;
  top: -2px;
  position: relative;

  &::part(native) {
    padding: 8px 12px;
  }
`;

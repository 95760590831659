import { IonContent, useIonViewDidEnter } from '@ionic/react';
import './styles/Home.css';
import './styles/menu.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PolakoTextSvg from '../../assets/images/text-with-sub.svg';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/Header';
import { LessonList } from './components/LessonList';
import { useShowTabs } from '../../hooks/useShowTabs';
import { Analytics } from '../../analytics';
import { SubscribeButton } from '../../components/SubscribeButton';
import { useThink } from '../../hooks/useThink';
import { useAccessToAllPermissions } from '../../components/SubscribeModal/useAccessToAllPermissions';
import { CorrectlySolved } from './components/CorrectlySolved';
import { isDevelopmentFeaturesEnabled } from '../../utils/isDevelopmentFeaturesEnabled';

export const MainScreen: FC<PropsWithChildren> = () => {
  useShowTabs();

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('MainScreen');
  }, []);

  const { showThinking, hideThinking } = useThink();
  const { isPermissionsLoading, getIsSubscriptionValid } =
    useAccessToAllPermissions();

  useEffect(() => {
    const manageShinking = async () => {
      // console.log('🤑 isPermissionsLoading', isPermissionsLoading);

      try {
        if (isPermissionsLoading) {
          await showThinking();
          // console.log('Show thinking');
        } else {
          await hideThinking();
          // console.log('Hide thinking');
        }
      } catch (e) {
        // console.log('12312312312312r', e);
      }
    };
    manageShinking();
  }, [isPermissionsLoading]);

  return (
    <>
      <Header>
        <SHeaderContent1 style={{ width: '150px' }}>
          <PolakoTextSvg />
          {isDevelopmentFeaturesEnabled() && (
            <SDevMessage>!!! DEV !!!</SDevMessage>
          )}
        </SHeaderContent1>

        <SHeaderContent2>
          {getIsSubscriptionValid() ? <CorrectlySolved /> : <SubscribeButton />}
        </SHeaderContent2>
      </Header>
      <IonContent>
        <SContent>
          <LessonList />
        </SContent>
      </IonContent>
    </>
  );
};

const SContent = styled.div`
  padding: var(--polako-padding);
  padding-bottom: var(--polako-padding-bottom);
`;

const SHeaderContent1 = styled.div``;
const SDevMessage = styled.div`
  font-weight: 800;
  text-align: center;
  color: var(--polako-white);
`;

const SHeaderContent2 = styled.div`
  max-width: 150px;
`;

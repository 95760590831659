export const trimAndRemovePunctuation = (str: string) => {
  return str
    .split(' ')
    .map((a) => a.trim())
    .map((a) => removePunctuationAtEnd(a));
};

function removePunctuationAtEnd(word: string): string {
  const punctuationChars = [
    '.',
    ',',
    '!',
    '?',
    ';',
    ':',
    '-',
    '…',
    '(',
    ')',
    '[',
    ']',
    '{',
    '}',
    '«',
    '»',
    '<',
    '>',
  ];
  if (punctuationChars.includes(word[word.length - 1])) {
    return word.slice(0, word.length - 1);
  }
  return word;
}

import { IonContent, IonPage } from '@ionic/react';
import { Header } from './Header';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { IonHeaderStyled } from './styled/styled';
import { SPagePaddingsWrapper } from './styles';

type TProps = {
  header?: ReactNode;
};

export const PageWrapper: FC<PropsWithChildren<TProps>> = ({
  children,
  header,
}) => {
  return (
    <IonPage>
      {header && (
        <IonHeaderStyled>
          <Header>{header}</Header>
        </IonHeaderStyled>
      )}

      <IonContent>
        <SPagePaddingsWrapper>{children}</SPagePaddingsWrapper>
      </IonContent>
    </IonPage>
  );
};

import { ProfileCard } from './ProfileCard';
import photoMe from '../../../../assets/images/authors/me-small.png';
import photoDesigner from '../../../../assets/images/authors/designer.webp';
import photoIllustrator from '../../../../assets/images/authors/illustrator2.jpeg';
import photoPsychologist from '../../../../assets/images/authors/psychologist.png';
import photoTeatcher from '../../../../assets/images/authors/teatcher.jpg';
import styled from 'styled-components';

export const OurPhotos = () => {
  return (
    <SProfileCardsWrapper>
      <ProfileCard
        imageUrl={photoMe}
        name="Даниил Кудрявцев"
        position="Программист, автор приложения"
      />

      <ProfileCard
        imageUrl={photoDesigner}
        name="Михаил Первушин"
        position="Дизайнер"
      />

      <ProfileCard
        imageUrl={photoTeatcher}
        name="Надан Кравич"
        position="Преподаватель сербского языка, автор текстов упражнений"
      />

      <ProfileCard
        imageUrl={photoIllustrator}
        name="Анастасия Тихонова"
        position="Иллюстратор"
      />

      {/*<ProfileCard*/}
      {/*  imageUrl={photoPsychologist}*/}
      {/*  name="Ксения Кудрявцева"*/}
      {/*  position="Психолог"*/}
      {/*/>*/}
    </SProfileCardsWrapper>
  );
};

const SProfileCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

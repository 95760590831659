import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';

export const Signup = () => {
  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://gmail.us10.list-manage.com/subscribe/post?u=42d19a4676441747520bc8fb8&amp;id=b8fb62a9c3&amp;f_id=00cbd8e5f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
        >
          <div>
            <SText>
              Для андроида – делаю 🙇‍️ <br />
              <br />
              Можно следить за процессом в{' '}
              <a href={'https://t.me/polakoappnews'}>телеграм-канале</a> <br />
              <br />
              Или оставь почту, я напишу когда выйдет
            </SText>
            <SFlex className="mc-field-group">
              <SInput
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
              />
              <Button
                square={false}
                size={'l'}
                fontSize={12}
                text={'Пиши сюда'}
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Subscribe"
              />
            </SFlex>
            <div
              aria-hidden="true"
              style={{ position: 'absolute', left: '-5000px' }}
            >
              <input
                type="text"
                name="b_42d19a4676441747520bc8fb8_b8fb62a9c3"
                tabIndex={-1}
                value=""
              />
            </div>
          </div>
          <SImgWrap
            dangerouslySetInnerHTML={{
              __html: `<a href="http://eepurl.com/iDmRHE" title="Mailchimp - email marketing made easy and fun"><span style="display: inline-block; background-color: transparent; border-radius: 4px;"><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a>`,
            }}
          />
        </form>
      </div>
    </div>
  );
};

const SImgWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const SFlex = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 20px;
`;

const SInput = styled.input`
  background-color: white;
  border: 1px solid black;
  font-size: 14px;
  padding: 5px;
  width: 100%;
`;

const SText = styled.h2`
  margin-top: 50px;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

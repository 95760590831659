import { useIOSSetAccessToAllPermissions } from '../../hooks/billing/useIOSSetAccessToAllPermissions';
import { Capacitor } from '@capacitor/core';
import { GlassfyFunctions } from '../../GlassfyFunctions';
import { useThink } from '../../hooks/useThink';
import { useShowCatForMsNew } from '../Game/components/EncouragingCat2/useShowCatForMsNew';

export const useRestorePurchases = () => {
  const { setBillingAccessToAllPermissions } =
    useIOSSetAccessToAllPermissions();
  const { showThinking, hideThinking } = useThink();
  const { showCat } = useShowCatForMsNew();

  const restorePurchases = async () => {
    await showThinking();
    try {
      if (Capacitor.getPlatform() === 'ios') {
        const res = await GlassfyFunctions.restorePurchases();
        setBillingAccessToAllPermissions(res);
      }

      if (Capacitor.getPlatform() === 'android') {
        // TODO android restore
        throw new Error('Android restore not implemented');
      }

      await hideThinking();
      showCat({
        text: `Все ок!`,
        textSub: 'Восстановлено!',
      });
    } catch (e) {
      showCat({
        text: `Какая-то ошибка при восстановлении :(`,
      });
      await hideThinking();
    }
  };

  return { restorePurchases };
};

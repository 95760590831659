export const initLandingGA = () => {
  // Динамическая загрузка gtag.js
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-7VLQZZM2T0`; // Ваш Measurement ID
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', 'G-7VLQZZM2T0', { debug_mode: true });
};

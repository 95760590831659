import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

export const initAppMetrics = () => {
  if (import.meta.env.MODE !== 'production') {
    return;
  }
  amplitude.init('a367b84dada67ed5d7e14576c4933fa');

  Sentry.init({
    dsn: 'https://79a5d22d868f4ffa9dc249b327453feb@o4505210929348608.ingest.sentry.io/4505528884527104',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

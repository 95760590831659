import React from 'react';
import styled from 'styled-components';

// Обертка для радио кнопки и визуального представления
const RadioButtonLabel = styled.div<{ checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 44px;
  min-width: 44px;
  height: 21px;
  background: ${(props) =>
    props.checked ? 'var(--polako-green)' : 'var(--polako-red)'};
  cursor: pointer;
  border: 1px solid black;
  box-shadow: var(--polako-shadow);
`;

const RadioButtonInput = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + div {
    left: 26px;
  }
`;

const RadioButtonCircle = styled.div`
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 3px;
  transition: left 0.2s;
  border: 1.2px solid black;
`;

type RadioButtonProps = {
  className?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Tumbler: React.FC<RadioButtonProps> = ({
  className,
  ...props
}) => (
  <RadioButtonLabel className={className} checked={props.checked}>
    <RadioButtonInput type="checkbox" {...props} />
    <RadioButtonCircle />
  </RadioButtonLabel>
);

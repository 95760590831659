import styled from 'styled-components';
import { useState } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import { useRuntimeStore } from '../../initApp';
import { StatCard } from './components/StatCard';
import { TStat } from '../../types';
import CatThumbUp from '../../assets/images/cat-thumb-up.svg';
import { getCountForm } from '../../utils/countForm';
import { DaysInRawText } from '../../components/DaysInRaw/DaysInRawText';
import { DaysInRawNumbers } from '../../components/DaysInRaw/DaysInRawNumbers';
import { useDaysInRawStat } from '../../components/DaysInRaw/useDaysInRawStat';
import { Address } from '../../components/Address';
import { Analytics } from '../../analytics';
import { useThink } from '../../hooks/useThink';

export const Stat = () => {
  const [statData, setStatData] = useState<TStat | null>(null);
  const { getStatPageData } = useRuntimeStore();
  const { showThinking, hideThinking } = useThink();

  useIonViewWillEnter(() => {
    getData();
    Analytics.setCurrentScreen('Stat');
  }, []);

  const getData = async function () {
    try {
      await showThinking();
      const stat = await getStatPageData();
      setStatData(stat);
      await hideThinking();
      Analytics.setCurrentScreen('Stat');
    } catch (e) {
      await hideThinking();
    }
  };

  const daysInRow = useDaysInRawStat();

  if (statData === null) return null;

  return (
    <>
      <SCardsWrapper>
        <StatCard title={statData.solved} desc="правильно решенных" />
        {/*<StatCard*/}
        {/*  title={secondsToHumanReadable(statData.time) || 'пока еще 0 минут'}*/}
        {/*  desc="проведено в упражнениях"*/}
        {/*/>*/}
        <StatCard
          title={statData.passedTopicsQty}
          desc={
            getCountForm(statData.passedTopicsQty, [
              'урок',
              'урока',
              'уроков',
            ]) + ' пройдено'
          }
        />
      </SCardsWrapper>
      {Boolean(daysInRow) && (
        <SDaysInRow>
          <DaysInRawText></DaysInRawText>
          <DaysInRawNumbers daysInRow={daysInRow}></DaysInRawNumbers>
        </SDaysInRow>
      )}
      <SCat>
        <CatThumbUp />
        <Address />
      </SCat>
    </>
  );
};

const SDaysInRow = styled.div`
  padding-top: 10px;
  position: relative;
  z-index: 2;
`;

const SCardsWrapper = styled.div`
  gap: 15px;
  display: flex;
  justify-content: center;
`;

const SCat = styled.div`
  width: 190px;
  margin: 0 auto;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding-bottom: 30px;
`;

import { useState } from 'react';

export const useShakeAnimation = (duration: number = 500) => {
  const [shouldShake, setShouldShake] = useState(false);

  const triggerShake = () => {
    setShouldShake(true);
    setTimeout(() => {
      setShouldShake(false);
    }, duration);
  };

  return [shouldShake, triggerShake] as const;
};

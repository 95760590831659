import { useEffect, useState } from 'react';

import {
  getFilledString,
  mixRealSentenceWordsWithFakeOnes,
} from '../utils/mixRealSentenceWordsWithFakeOnes';
import { TTask, TTasksResp, TTopic } from '../types';
import { fillForeignWithWords } from '../utils/fillForeignWithWords';
import { getNextTaskWhichWasNotSelectedYet } from '../utils/getNextTaskWhichWasNotSelectedYet';
import { useGetMode } from './useGetMode';
import { useTrackTimeSpentInApp } from './useTrackTimeSpentInApp';
import { usePressOnWord } from './usePressOnWord';
import { useReadyButtonHandler } from './useReadyButtonHandler';
import { getIsOnlyGapMode } from '../utils/getIsOnlyGapMode';
import { useEncouragingCat } from './useEncouragingCat/useEncourangingCat';
import { trimAndRemovePunctuation } from '../utils/trimAndRemovePunctuation';
import { useRuntimeStore } from '../../../initApp';
import { Analytics } from '../../../analytics';
import { useThink } from '../../../hooks/useThink';

export const useGame = (topic: TTopic) => {
  const [currentTask, setCurrentTask] = useState<TTask | null>(null);
  const [allTasks, setAllTasks] = useState<TTask[]>([]);
  const [currentVideos, setCurrentVideos] = useState<string[] | null>(null);
  const [tipText, setTipText] = useState<string | null>(null);

  const [correctlyAnsweredCounter, setCorrectlyAnsweredCounter] =
    useState<number>(0);

  const [wordsToChooseFrom, setWordsToChooseFrom] = useState<string[]>();
  const { mode, setRandomMode } = useGetMode(currentTask);
  const { incrementTime } = useTrackTimeSpentInApp(topic);

  const {
    getPassedItems,
    incrementGiveUp,
    getCorrectlySolvedCount,
    getLesson,
  } = useRuntimeStore();

  const {
    wordClickHandler,
    userSelectedWordsArr,
    alreadyPressedIndexes,
    resetClickedWords,
    clearLastSelectedWord,
  } = usePressOnWord({ currentTask, mode });

  const { showThinking, hideThinking } = useThink();

  useEffect(() => {
    const initGame = async () => {
      try {
        showThinking();
        const apiUrl = `/assets/lessons/${topic.file}`;
        const resp = await fetch(apiUrl);
        const tasks: TTasksResp = await resp.json();
        const currentTask = await getCurrentTask(tasks.drills);
        // const currentTask = MOCK;
        const currentVideos = tasks.videos;
        // setAllTasks([MOCK]);
        setAllTasks(tasks.drills);
        setCurrentVideos(currentVideos);

        setNewTask(currentTask);
        hideThinking();
      } catch (error) {
        hideThinking();
        console.error({ error });
      }
    };

    initGame();
  }, [topic.path]);

  const fillWordsToChooseFrom = (task: TTask | null) => {
    if (!task) {
      return;
    }

    const result =
      mode.current == 'full'
        ? mixRealSentenceWordsWithFakeOnes(task)
        : task.random;

    setWordsToChooseFrom([...result]);
  };

  const isReady = !!currentTask;

  const getTaskWhichIsNotSolvedYet = async (allTasks: TTask[]) => {
    const passedTasksIds = await getPassedItems(topic);
    return getNextTaskWhichWasNotSelectedYet(allTasks, passedTasksIds);
  };

  const getCurrentTask = async (allTasks: TTask[]) => {
    const task = await getTaskWhichIsNotSolvedYet(allTasks);
    return task;
  };

  const setNewTask = (task: TTask) => {
    setRandomMode(task);
    setCurrentTask(task);
    fillWordsToChooseFrom(task);
  };

  const nextClickHandler = async () => {
    setCorrectlyAnsweredCounter(correctlyAnsweredCounter + 1);

    const nextTask = await getTaskWhichIsNotSolvedYet(allTasks);
    resetClickedWords();
    incrementTime();
    setIsCorrect(null);
    setNewTask(nextTask);
  };

  const mistakeHandler = () => {
    if (mode.current === 'part') {
      resetClickedWords();
    }
    setIsCorrect(null);
  };

  const getUserTypedStr = (): string => {
    if (!isReady) return '';
    let res = '';
    if (mode.current === 'full') {
      res = userSelectedWordsArr.join(' ');
    } else {
      res = getFilledString(userSelectedWordsArr, currentTask.foreign);
    }

    return trimAndRemovePunctuation(res).join(' ');
  };

  const getFilledSentenceStr = () =>
    trimAndRemovePunctuation(fillForeignWithWords(currentTask)).join(' ');

  const { handleStreakAnswer } = useEncouragingCat();

  const { lessonStat, readyClickHandler, isCorrect, setIsCorrect } =
    useReadyButtonHandler({
      topic,
      currentTask,
      getUserTypedStr,
      getFilledSentenceStr,
      getLesson,
      mistakeHandler,
      handleStreakAnswer,
    });

  const showTipClickHandler = async () => {
    if (!currentTask) {
      return;
    }

    const text = getFilledSentenceStr();

    Analytics.trackTipShown(topic.path);
    setTipText(text);
    incrementGiveUp(topic);
  };

  const undoClickHandler = () => {
    clearLastSelectedWord();
    setIsCorrect(null);
  };

  const getIfShouldOpenBook = async () => {
    const lessonStat = await getLesson(topic.path);

    if (!lessonStat) {
      return true;
    }

    return lessonStat.solvedIds.length <= 0;
  };

  const isOnlyButtonMode = getIsOnlyGapMode(currentTask);

  console.log(lessonStat);

  return {
    wordClickHandler,
    readyClickHandler,
    nextClickHandler,
    undoClickHandler,
    showTipClickHandler,

    isCorrect,
    userSelectedWordsArr,
    currentSentence: currentTask,
    currentVideos,
    wordsToChooseFrom,
    alreadyPressedIndexes,
    correctlyAnsweredCounter,
    userSentenceStr: getUserTypedStr(),
    isReady,
    tipText,
    setTipText,
    getCorrectlySolvedCount,
    lessonStat,
    getIfShouldOpenBook,
    isOnlyButtonMode,
  };
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from './store';
import * as amplitude from '@amplitude/analytics-browser';
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { firebaseConfig } from './firebaseConfig';
import { Landing } from './pages/Landing/Landing';
import { AppWrapper } from './AppSentryWrapper';
import { initLandingGA } from './pages/Landing/initLandingGA';
import { initLandingYandexMetrika } from './pages/Landing/initLandingYandexMetrika';
import { initAppMetrics } from './initAppMetrics';
import { Capacitor } from '@capacitor/core';
import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
import { GlassfyFunctions } from './GlassfyFunctions';
import { RevenueCatFunctions } from './RevenueCatFunctions';

const USE_EMULATOR = false;

const container = document.getElementById('root');
const root = createRoot(container!);

const showLanding =
  location.hostname == 'polako.app' || import.meta.env.VITE_APP === 'landing';

export const useRuntimeStore = createStore();

const renderLanding = () => {
  initLandingGA();
  initLandingYandexMetrika();

  const params = new URLSearchParams(window.location.search);

  if (params.has('qr')) {
    const qr = params.get('qr');
    window.gtag('event', 'qr_' + qr, { debug_mode: true });
  }

  root.render(<Landing />);
};

const renderApp = async () => {
  initAppMetrics();
  initializeFirestore(initializeApp(firebaseConfig), {});
  if (Capacitor.getPlatform() === 'ios') {
    await GlassfyFunctions.initializeApp();
  }

  // if (Capacitor.getPlatform() === 'android') {
  //   RevenueCatFunctions.initializeApp();
  // }

  root.render(<AppWrapper />);
};

if (showLanding) {
  renderLanding();
} else {
  renderApp();
}

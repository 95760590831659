import { useRuntimeStore } from '../../initApp';
import { GlassfyPermissions } from 'capacitor-plugin-glassfy';

export const useIOSSetAccessToAllPermissions = () => {
  const setAccessToAllPermission = useRuntimeStore(
    (s) => s.setAccessToAllPermission
  );

  return {
    setBillingAccessToAllPermissions: (
      permissions: GlassfyPermissions,
      successCb?: () => void
    ) => {
      const validPermission = permissions?.all.find((permission) => {
        return (
          permission.permissionId === 'access_to_all_lessons' &&
          permission.isValid
        );
      });

      if (validPermission) {
        setAccessToAllPermission({
          expireDate: Number(validPermission.expireDate) * 1000,
        });
        successCb?.();
        return true;
      }

      return false;
    },
  };
};

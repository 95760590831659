import { Glassfy } from 'capacitor-plugin-glassfy';
// import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
// import { PurchaseSubscriptionOptionOptions } from '@revenuecat/purchases-capacitor/dist/esm/definitions';

const initializeApp = async (appUserID: string) => {
  try {
    // await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    // await Purchases.configure({
    //   apiKey: 'goog_VxSkgCKIAPsZnjnlkrOUFMLGydT',
    //   appUserID,
    // });
    // console.log('✅ RevenueCat initialized');
  } catch (e) {
    // initialization error
    console.log('RevenueCat Initialization Error:', e);
  }
};

const restorePurchases = async () => {};

const getPermissions = async () => {
  // return Purchases.getCustomerInfo();
};

const getOfferings = async () => {
  // return Purchases.getOfferings();
};

const purchaseSku = async (option) => {
  // return Purchases.pur(option);
  // TODO: Add a check for the platform
};

export const RevenueCatFunctions = {
  initializeApp,
  getOfferings,
  purchaseSku,
  getPermissions,
};

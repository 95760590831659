// @ts-ignore
import CatBulbImg from '../../assets/images/cat-bulb.svg';
import styled from 'styled-components';
import { Modal } from '../Modal/Modal';
import iconCorrect from '../../assets/icons/correct.svg?inline';
import { Button } from '../Button';

import { TimeInput } from '../TimeInput';
import { useLocalNotifications } from './useLocalNotifications';
import { useShowCatForMsNew } from '../../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
import { useRuntimeStore } from '../../initApp';
import { useEffect } from 'react';
import { Analytics } from '../../analytics';

type TProps = {
  close: () => void;
  autoClose?: boolean;
};

export const NotificationModal = ({ close }: TProps) => {
  const { time, setTime, setRepeatedNotificationsTime } =
    useLocalNotifications();

  const { showCat } = useShowCatForMsNew();
  const saveLocalPushNotificationsTime = useRuntimeStore(
    (s) => s.saveLocalPushNotificationsTime
  );

  useEffect(() => {
    setTime('21:00');
  }, []);

  return (
    <>
      <Modal
        position="bottom"
        close={async () => {
          if (!time) {
            await saveLocalPushNotificationsTime(null);

            showCat({
              text: 'Не буду напоминать',
              textSub: 'Если что - потом установишь в настройках',
            });

            Analytics.cancelNotificationsFromFirstPrompt();
          }
          close();
        }}
      >
        <SModal>
          <SCat>
            <CatBulbImg />
          </SCat>
          <SText>
            Хочешь, я буду напоминать тебе о твоем желании учить язык?
            <SEveryDay>Каждый день в:</SEveryDay>
          </SText>

          <STimeAndApprove>
            <TimeInput time={time} setTime={setTime} />
            <Button
              icon={iconCorrect}
              size={'m'}
              color="green"
              fontSize={22}
              onClick={async () => {
                await setRepeatedNotificationsTime();
                close();
              }}
            ></Button>
          </STimeAndApprove>
        </SModal>
      </Modal>
    </>
  );
};

const STimeAndApprove = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const SEveryDay = styled.div`
  font-weight: 800;
  margin-bottom: 20px;
`;

const SModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

const SText = styled.div`
  font-size: 14px;
  padding: 0 40px;
  text-align: center;
`;

const SCat = styled.div`
  margin-bottom: 20px;
`;

import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--polako-black);
`;

export const H1Top = styled(H1)`
  margin-top: 0;
`;

export const H1Header = styled(H1)`
  margin-top: 0;
  margin-bottom: 0;
`;

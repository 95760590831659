import styled from 'styled-components';

export const ButtonSimple = styled.button`
  font-weight: 600;
  font-size: 12px;
  height: 38px;
  box-shadow: var(--polako-shadow);
  background: var(--polako-white);
  padding: 5px;
  border: 1px solid var(--polako-black);
  position: relative;
  cursor: default;
  color: var(--polako-black);

  &:active {
    box-shadow: none;
    top: 4px;
    left: 4px;
  }
`;

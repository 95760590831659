import { useRuntimeStore } from '../../initApp';
// import { CustomerInfo } from '@revenuecat/purchases-capacitor';
// import { MONTH_ANDROID } from '../../components/SubscribeModal/android/constants';

export const useAndroidBillingSetAccessToAllPermissions = () => {
  const setAccessToAllPermission = useRuntimeStore(
    (s) => s.setAccessToAllPermission
  );

  return {
    setBillingAccessToAllPermissions: (
      // permissions: CustomerInfo, .. Temp until paid
      successCb?: () => void
    ) => {
      // const activeEntitlement = permissions.entitlements.active[MONTH_ANDROID];
      const activeEntitlement = true;

      if (activeEntitlement) {
        setAccessToAllPermission({
          // expireDate: activeEntitlement.expirationDateMillis!,
          expireDate: 4882010858000, // September 14, 2124
        });
        successCb?.();
        return true;
      }

      return false;
    },
  };
};

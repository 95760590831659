import { Typo } from '../../components/Typo';
import React from 'react';
import { useAccessToAllPermissions } from '../../components/SubscribeModal/useAccessToAllPermissions';
import styled from 'styled-components';
import { SubscriptionContent } from '../../components/SubscribeModal/components/SubscriptionContent';
// import { SubscribeModalAndroid } from '../../components/SubscribeModal/android/SubscribeModalAndroid';
import { Button } from '../../components/Button';
import { useRuntimeStore } from '../../initApp';
import { getSubscriptionExpiringDateStr } from './utils/getSubscriptionExpiringDateStr';
// import { Capacitor } from '@capacitor/core';
import { SubscribeModalIos } from '../../components/SubscribeModal/ios/SubscribeModalIos';

export const SubscriptionBlock = () => {
  const {
    getIsSubscriptionValid,
    isPermissionsLoading,
    accessToAllPermission,
  } = useAccessToAllPermissions();

  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );
  const isSubscriptionPopupVisible = useRuntimeStore(
    (s) => s.isSubscriptionPopupVisible
  );

  // const PopupComponent =
  //   Capacitor.getPlatform() === 'ios' ? (
  //     SubscribeModalIos
  //   ) : (
  // : SubscribeModalAndroid;
  // );
  const PopupComponent = SubscribeModalIos;

  return (
    <>
      {isPermissionsLoading && <Typo.Text>Загрузка...</Typo.Text>}

      {getIsSubscriptionValid() && (
        <SubscriptionContentStyled title="Подписка">
          <Typo.Text>
            Ваша подписка активна до{' '}
            {getSubscriptionExpiringDateStr(accessToAllPermission?.expireDate)}
          </Typo.Text>
        </SubscriptionContentStyled>
      )}

      {!isPermissionsLoading && !getIsSubscriptionValid() && (
        <SubscriptionContentStyled title="Подписка не активна">
          <Typo.Text>
            Оформите подписку, чтобы получить доступ ко всем урокам
          </Typo.Text>

          <br />
          <Button
            square={false}
            text={'Оформить подписку'}
            onClick={() => toggleSubscriptionPopup()}
            size={'m'}
            wide={true}
            fontSize={12}
            color={'yellow'}
          ></Button>
        </SubscriptionContentStyled>
      )}

      {isSubscriptionPopupVisible && (
        <PopupComponent close={toggleSubscriptionPopup} />
      )}
    </>
  );
};

const SubscriptionContentStyled = styled(SubscriptionContent)`
  background-color: var(--polako-green);
  width: 100%;
`;

import React, { useEffect, useState } from 'react';
import DialogBoxRight from '../../../../assets/images/dialog-box-right.svg';
import DialogBoxLeft from '../../../../assets/images/dialog-box-left.svg';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  AnimatedDiv,
  CatLooksLeftStyled,
  CatLooksRightStyled,
  SDialog,
  SText,
  STextMain,
  STextSub,
} from './styles';
import { TShowCatOptions } from './types';
import PubSub from 'pubsub-js';
import { CAT_ON_SCREEN } from '../../../../constants';
import { createPortal } from 'react-dom';

export const EncouragingCatNew = () => {
  const [isShown, setIsShown] = useState(false);
  const [options, setOptions] = useState<TShowCatOptions | null>();
  useEffect(() => {
    const showToken = PubSub.subscribe(
      'showCat',
      (_, data: TShowCatOptions) => {
        setIsShown(true);
        setOptions(data);
      }
    );

    const hideToken = PubSub.subscribe('hideCat', () => {
      setIsShown(false);
      setOptions(null);
    });

    return () => {
      PubSub.unsubscribe(showToken);
      PubSub.unsubscribe(hideToken);
    };
  }, []);

  useEffect(() => {
    if (!options) {
      return;
    }

    const tm = setTimeout(
      () => {
        setIsShown(false);
      },
      options?.duration || CAT_ON_SCREEN
    );

    return () => {
      clearTimeout(tm);
    };
  }, [options]);

  const side = options?.side || 'right';

  return createPortal(
    <TransitionGroup>
      {isShown && options && (
        <CSSTransition classNames="fade" timeout={1000}>
          <AnimatedDiv side={side}>
            {side === 'right' && <CatLooksRightStyled />}
            {side === 'left' && <CatLooksLeftStyled />}

            <SDialog side={side}>
              <SText>
                <STextMain>{options.text}</STextMain>
                {options.textSub && <STextSub>{options.textSub}</STextSub>}
              </SText>
              {side === 'right' ? <DialogBoxRight /> : <DialogBoxLeft />}
            </SDialog>
          </AnimatedDiv>
        </CSSTransition>
      )}
    </TransitionGroup>,
    document.body
  );
};

import { SIconWrapper } from '../../../components/styles';
import React, { FC } from 'react';
import styled from 'styled-components';

const getColor = (mark: number) => {
  if (mark > 4) {
    return 'green';
  }

  return 'red';
};

function getInterpolatedRGBColor(
  rating: number,
  startRGB: number[],
  endRGB: number[]
): string {
  if (rating < 1 || rating > 5) {
    throw new Error('Rating should be between 1 and 5');
  }
  if (startRGB.length !== 3 || endRGB.length !== 3) {
    throw new Error('Both RGB arrays should have exactly 3 elements');
  }

  const interpolationFactor = (rating - 1) / 4;

  const red = Math.round(
    startRGB[0] + (endRGB[0] - startRGB[0]) * interpolationFactor
  );
  const green = Math.round(
    startRGB[1] + (endRGB[1] - startRGB[1]) * interpolationFactor
  );
  const blue = Math.round(
    startRGB[2] + (endRGB[2] - startRGB[2]) * interpolationFactor
  );

  return `rgb(${red}, ${green}, ${blue})`;
}

type TProps = { mark: number };

export const LessonMark: FC<TProps> = ({ mark }) => {
  return (
    <SIconWrapperStyled size={'s'} $square={false} $wide={false} $mark={mark}>
      {mark}
    </SIconWrapperStyled>
  );
};

const SIconWrapperStyled = styled(SIconWrapper)<{ $mark: number }>`
  min-height: 36px;
  min-width: 36px;
  font-size: 16px;
  padding: 6px 12px;
  background-color: ${({ $mark }) =>
    getInterpolatedRGBColor($mark, [255, 128, 128], [128, 255, 157])};
`;

import PubSub from 'pubsub-js';
import { TShowCatOptions } from './types';

export const useShowCatForMsNew = () => {
  const showCat = (options: TShowCatOptions) => {
    PubSub.publish('showCat', options);
  };

  return {
    showCat,
  };
};

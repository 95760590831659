import { MAXIMUM_SCORE, MINIMUM_SCORE } from '../../../../constants';

export const incrementBasic = (val: number, incrementStep = 1) =>
  val + incrementStep;

export const incrementScoreBasic = (val: number) =>
  val + 0.1 > MAXIMUM_SCORE ? MAXIMUM_SCORE : val + 0.1;

export const decrementScoreBasic = (val: number) =>
  val - 0.2 <= MINIMUM_SCORE ? MINIMUM_SCORE : val - 0.2;

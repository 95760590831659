import React from 'react';
import { Modal } from '../../../components/Modal/Modal';
import styled from 'styled-components';

type TProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export function Feedback({ showModal, setShowModal }: TProps) {
  if (!showModal) {
    return null;
  }

  return (
    <ModalStyled close={() => setShowModal(false)} withBackdrop={false}>
      <iframe
        src="https://us10.list-manage.com/contact-form?u=42d19a4676441747520bc8fb8&form_id=08ff591dd6da512c50d1391f5bd24c8a"
        width="100%"
        height="600"
        frameBorder="0"
        allowFullScreen
        title="Фидбэк"
      ></iframe>
    </ModalStyled>
  );
}

const ModalStyled = styled(Modal)`
  max-height: 60vh;
`;

import { SIconAndText, SIconDesc, SIconText } from './styles';
import BackIcon from '../assets/icons/back.svg?inline';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from './IconButton';

export const BackButton = ({ text }: { text: string }) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.replace('/learn');
  };

  return (
    <SIconAndText onClick={handleButtonClick}>
      <IconButton icon={BackIcon} />
      <SIconDesc>
        <SIconText>{text}</SIconText>
      </SIconDesc>
    </SIconAndText>
  );
};

import { shuffle } from './shuffle';
import { TTask } from '../types';
import { trimAndRemovePunctuation } from './trimAndRemovePunctuation';

export const mixRealSentenceWordsWithFakeOnes = (data: TTask): string[] => {
  if (!data) {
    return [];
  }
  const foreign = trimAndRemovePunctuation(data.foreign).filter(
    (i) => i && i !== '_'
  );

  const random = data?.random || [];

  const allTogetgerShuffled = shuffle([...foreign, ...random]);

  return allTogetgerShuffled;
};

type TPlaceholderArray = string[];

export const getFilledString = (
  placeholders: TPlaceholderArray,
  template: string
): string => {
  let result: string = template;
  for (const placeholder of placeholders) {
    result = result.replace('_', placeholder);
  }
  return result;
};

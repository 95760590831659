import styled from 'styled-components';
import { bordersCss } from './styles';
import React, { FC } from 'react';
import { TNotificationTime } from '../types';
import { IonIcon } from '@ionic/react';
import editIcon from '../assets/icons/edit.svg';

type TimeInputProps = {
  time: TNotificationTime;
  setTime: (time: string) => void;
  isDisabled?: boolean;
  confirmSelectedTimeHandler?: () => void;
};

export const TimeInput: FC<TimeInputProps> = ({
  time,
  setTime,
  isDisabled = false,
  confirmSelectedTimeHandler,
}) => {
  return (
    <SInputTimeWrapper>
      <SInputTime
        disabled={isDisabled}
        type={'time'}
        onChange={(e) => {
          setTime(e.target.value);
        }}
        onBlur={() => {
          confirmSelectedTimeHandler?.();
        }}
        value={time}
      />
      <SIcon>
        <IonIcon src={editIcon}></IonIcon>
      </SIcon>
    </SInputTimeWrapper>
  );
};

const SInputTime = styled.input`
  background-color: var(--polako-white);
  font-size: 14px;
  border: none;
  border-radius: 0px;
  font-weight: 700;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 88px;
`;

const SInputTimeWrapper = styled.div`
  position: relative;

  ${bordersCss};
  box-shadow: var(--polako-shadow);
  background: white;
  color: var(--polako-black);
  border-radius: 0;
  border-color: #000;
  border-style: solid;
  border-width: 1px;
  height: 32px;
  width: 90px;
`;

const SIcon = styled.span`
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 20px;
  pointer-events: none;
`;

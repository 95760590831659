import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../components/Button';

type TWordButtonProps = {
  onClick: () => void;
  isPressed: boolean;
  children: React.ReactNode;
};

export const WordButton: FC<TWordButtonProps> = ({
  onClick,
  isPressed,
  children,
}) => {
  return (
    <ButtonStyled
      $isPressed={isPressed}
      square={false}
      color={'white'}
      fontSize={18}
      size={'l'}
      onClick={onClick}
    >
      {children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)<{ $isPressed: boolean }>`
  text-transform: none;
  &::part(native) {
    padding: 13px;
    font-weight: 700;
    height: auto;
    font-size: 13px;

    ${({ $isPressed }) => {
      return (
        $isPressed &&
        css`
          background-color: var(--polako-gray);
          box-shadow: none;
          right: -2px;
          top: 2px;
        `
      );
    }}
  }
`;

import { useRef } from 'react';
import { TTopic } from '../types';
import { useRuntimeStore } from '../../../initApp';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';

export const useTrackTimeSpentInApp = (topic: TTopic) => {
  const { incrementTimeSpentLearning } = useRuntimeStore();
  const timeRef = useRef<number>(new Date().getTime());

  useIonViewWillLeave(() => {
    // console.log('Вышли');
    timeRef.current = 0;
  }, []);

  useIonViewDidEnter(() => {
    // console.log('Зашли');
    timeRef.current = new Date().getTime();
  }, []);

  const incrementTime = async () => {
    // it glitches now so I jus turn it off for a while
    return;
    const time = new Date().getTime();
    const diffMs = time - timeRef.current;
    const seconds = Math.round(diffMs / 1000);
    await incrementTimeSpentLearning(topic, seconds);
  };

  return { incrementTime };
};

import { TStorageLessons } from './pages/Game/types';
import { ChangeEvent } from 'react';

export type TStoreKey = string;
export type TStat = {
  solved: number;
  time: number;
  passedTopicsQty: number;
};

export type TCommonTimeStamp = number;

export type TManualSubscription = {
  dateStart: TCommonTimeStamp;
  dateEnd: TCommonTimeStamp;
};

export type TPermission = {
  expireDate: TCommonTimeStamp;
};

export type TUserData = {
  version: number;
  visitDates: string[];
  lessons: TStorageLessons;
  localPushNotifications: TNotificationTime;
  manualSubscriptions: TManualSubscription[];
};

export type TNotificationTime = string | undefined | null;

type MigrationScript = (data: TUserData, dataKey: TStoreKey) => void;
export type TMigrations = Record<number, MigrationScript>;

type GTagType = (
  eventName: string,
  eventParams: string | object,
  options?: object
) => void;

export type TBlockReason =
  | 'notReleasedYet'
  | 'internalError'
  | 'subscription'
  | 'prevLessonNotPassed';

declare global {
  interface Window {
    gtag: GTagType;
  }
}

export type TChangeEventHandler = (
  event: ChangeEvent<HTMLInputElement>
) => void;

export enum PERIODS {
  P1M = '1 месяц',
  P6M = '6 месяцев',
}

export type TPolakoOffering = {
  id: string;
  title: string;
  description: string;
  price: string;
  currencyCode: string;
  period: string | null;
};

import React, { FC } from 'react';
import iconClose from '../assets/icons/close.svg?inline';
import styled from 'styled-components';
import { IconButton, TIconButton } from './IconButton';

type TProps = Omit<TIconButton, 'color' | 'icon'>;

export const CloseButton: FC<TProps> = ({ fontSize = 18, ...props }) => {
  return (
    <IconButtonStyled
      {...props}
      fontSize={fontSize}
      icon={iconClose}
    ></IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: 0;
  width: 32px;
  height: 32px;
`;

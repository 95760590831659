import { useEffect } from 'react';
import { useRuntimeStore } from '../../initApp';
import { useIonViewDidEnter } from '@ionic/react';

export const useDaysInRawStat = () => {
  const { getConsecutiveDays, consecutiveDays } = useRuntimeStore();

  useEffect(() => {
    getConsecutiveDays();
  }, []);

  useIonViewDidEnter(() => {
    getConsecutiveDays();
  }, []);

  return consecutiveDays;
};

import * as amplitude from '@amplitude/analytics-browser';
import { TTopicPath } from './pages/Game/types';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

import * as Sentry from '@sentry/react';

export const Analytics = {
  async setUserId(userId?: string) {
    if (!userId) return;

    amplitude.setUserId(userId);

    try {
      await FirebaseAnalytics.setUserId({
        userId,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  setCurrentScreen: async (screenName: string) => {
    await FirebaseAnalytics.setCurrentScreen({
      screenName,
    });
  },

  setSignUp: async () => {
    FirebaseAnalytics.logEvent({
      name: 'Sign Up',
    });
  },

  setSignIn: async () => {
    FirebaseAnalytics.logEvent({
      name: 'Sign In',
    });
  },

  trackDaysInRaw: (daysInRow: number | null | undefined) => {
    if (!daysInRow) return;

    amplitude.track('Days in row', {
      daysInRow,
    });

    FirebaseAnalytics.logEvent({
      name: 'Days in row',
      params: { daysInRow },
    });
  },

  trackTipShown: (topicPath: TTopicPath) => {
    amplitude.track('Tip showed', {
      lessonName: topicPath,
    });

    FirebaseAnalytics.logEvent({
      name: 'tip_showed',
      params: { topicPath },
    });
  },

  setNotifications: () => {
    amplitude.track('notifications_set_up');

    FirebaseAnalytics.logEvent({
      name: 'notifications_set_up',
    });
  },

  cancelNotificationsFromSettings: () => {
    amplitude.track('notifications_cancel_from_settings');

    FirebaseAnalytics.logEvent({
      name: 'notifications_cancel_from_settings',
    });
  },

  cancelNotificationsFromFirstPrompt: () => {
    amplitude.track('notifications_cancel_from_first_prompt');

    FirebaseAnalytics.logEvent({
      name: 'notifications_cancel_from_first_prompt',
    });
  },

  trackLessonOpened: (order: number) => {
    amplitude.track('Lesson opened', {
      lessonOrder: order,
    });

    FirebaseAnalytics.logEvent({
      name: 'Lesson opened',
      params: { order },
    });
  },

  trackAppOpened: (platform: string) => {
    amplitude.track('App Opened', {
      platform,
    });

    FirebaseAnalytics.logEvent({
      name: 'App Opened',
      params: { platform },
    });
  },

  trackLessonUnblocked: (lessonOrder: number) => {
    amplitude.track('Lesson unblocked', {
      lessonOrder,
    });

    FirebaseAnalytics.logEvent({
      name: 'Lesson unblocked',
      params: { lessonOrder },
    });
  },
};

import React, { useState, useEffect } from 'react';

export const SubscriptionCalculator: React.FC = () => {
  const [numPeople, setNumPeople] = useState<number>(0);
  const [subscriptionType, setSubscriptionType] = useState<string>('month');
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
  const [futureTimestamp, setFutureTimestamp] = useState<number>(0);

  useEffect(() => {
    const calculateTimestamps = () => {
      const currentDate = new Date();
      const currentTime = Math.floor(currentDate.getTime() / 1000);
      setCurrentTimestamp(currentTime);

      let futureDate = new Date(currentDate);

      switch (subscriptionType) {
        case 'month':
          futureDate.setMonth(futureDate.getMonth() + 1);
          break;
        case 'halfYear':
          futureDate.setMonth(futureDate.getMonth() + 6);
          break;
        case 'year':
          futureDate.setFullYear(futureDate.getFullYear() + 1);
          break;
        case 'unlimited':
          futureDate = new Date(Date.now() + Number.MAX_SAFE_INTEGER);
          break;
        default:
          break;
      }

      const futureTime = Math.floor(futureDate.getTime() / 1000);
      setFutureTimestamp(futureTime);
    };

    calculateTimestamps();
  }, [numPeople, subscriptionType]);

  const copyToClipboard = (timestamp: number) => {
    navigator.clipboard.writeText(timestamp.toString());
  };

  return (
    <div>
      <h1>Калькулятор подписки</h1>
      <br />
      <br />
      <div>
        <label htmlFor="subscriptionType">Тип подписки:</label>
        <select
          id="subscriptionType"
          value={subscriptionType}
          onChange={(e) => setSubscriptionType(e.target.value)}
        >
          <option value="month">Месяц</option>
          <option value="halfYear">Полгода</option>
          <option value="year">Год</option>
          <option value="unlimited">Бессрочная</option>
        </select>
      </div>
      <div>
        <p>
          Текущее время: {currentTimestamp}
          <br />
          <button onClick={() => copyToClipboard(currentTimestamp)}>
            Копировать
          </button>
        </p>
        <p>
          Подписка действительна до: {futureTimestamp}
          <br />
          <button onClick={() => copyToClipboard(futureTimestamp)}>
            Копировать
          </button>
        </p>
      </div>
    </div>
  );
};

export default SubscriptionCalculator;

import { SDays, SDaysOuter } from '../../pages/Intro/styles';
import { getCountForm } from '../../utils/countForm';

type TProps = { daysInRow: number };

export const DaysInRawNumbers = ({ daysInRow }: TProps) => {
  return (
    <SDaysOuter>
      <SDays>
        {daysInRow} {getCountForm(daysInRow, ['день', 'дня', 'дней'])}
      </SDays>
    </SDaysOuter>
  );
};

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useThink } from './useThink';
import { useRuntimeStore } from '../initApp';
import { useShowCatForMsNew } from '../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
import * as Sentry from '@sentry/browser';
import { useSetUserAndConnectBilling } from './billing/useSetUserAndConnectBilling';

const authStrategies = {
  apple: FirebaseAuthentication.signInWithApple,
  google: FirebaseAuthentication.signInWithGoogle,
  anonymous: FirebaseAuthentication.signInAnonymously,
};

export const useAuth = () => {
  const { showThinking, hideThinking } = useThink();
  const { setUser } = useRuntimeStore();
  const { showCat } = useShowCatForMsNew();
  const { setUserAndConnectBilling } = useSetUserAndConnectBilling();

  const signIn = async (
    strategy: 'apple' | 'google' | 'anonymous',
    // Это из-за этого извращения с логином, который есть в AuthSubscriber.tsx
    // Иногда мы можем ставить юзера сразу, иногда - нет. Зависит от того мы на главной странице логина, или юзер уже имеет анонимный акаунт.
    // Если имеет, то надо переопределить его.
    shouldSetUser = false
  ) => {
    try {
      await showThinking();
      console.log('showThinking useAuth');
      const authResult = await authStrategies[strategy]();
      await hideThinking();
      if (shouldSetUser) {
        await setUserAndConnectBilling(authResult.user);
      }
      showCat({
        text:
          strategy === 'anonymous'
            ? 'Попробуй пока так, ага'
            : 'Отлично авторизовались',
      });
      console.log('Залогинен signIn', authResult);
      return authResult;
    } catch (e) {
      await hideThinking();
      console.error(e);
      showCat({
        text: 'Блин, ошибка :( Попробуй еще раз',
      });
      Sentry.captureException(e);
      throw e;
    }
  };

  const logOut = async () => {
    try {
      await FirebaseAuthentication.signOut();
      setUser(null);
      showCat({
        text: 'Увидимся!',
      });
      console.log('Вы вышли из системы со страницы Settings');
    } catch (e) {
      hideThinking();
      showCat({
        text: 'Блин, ошибка :( Попробуй еще раз',
      });
      Sentry.captureException(e);
      console.log('Ошибка выхода из системы');
    }
  };

  const removeAnonymousAccount = async () => {
    await FirebaseAuthentication.deleteUser();
    setUser(null);
    showCat({ text: 'Заходи еще!' });
  };

  const linkWithSocialAccount = async (
    provider: 'google' | 'apple',
    cb?: () => void
  ) => {
    try {
      await showThinking();
      const signInResult =
        provider === 'google'
          ? await FirebaseAuthentication.linkWithGoogle()
          : await FirebaseAuthentication.linkWithApple();
      cb?.();
      await setUserAndConnectBilling(signInResult.user);

      await hideThinking();
      showCat({
        text: 'Отлично залогинились!',
      });
      return signInResult;
    } catch (e) {
      // @ts-ignore
      if (e.code?.includes('credential-already-in-use')) {
        showCat({
          text: 'У тебя уже есть акаунт. Просто залогинься!',
        });
      } else {
        showCat({
          text: JSON.stringify(e),
        });
      }
      await hideThinking();
      console.log(e);
      Sentry.captureException(e);
      throw e;
      console.log('Ошибка привязки к ' + provider);
    }
  };

  return {
    signIn,
    logOut,
    linkWithSocialAccount,
    removeAnonymousAccount,
  };
};

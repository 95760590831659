import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Modal } from '../../../components/Modal/Modal';
import styled from 'styled-components';
import { TTopic } from '../types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CatTeacherImg from '../../../assets/images/cat-teacher-v1.svg';
import { H1Top } from '../../../components/H1';
import { Button } from '../../../components/Button';
import { TNotificationTime } from '../../../types';
type TProps = {
  close(): void;
  topic: TTopic;
  videos: string[] | null;
};

export function Book({ close, topic, videos }: TProps) {
  const [markdownContent, setMarkdownContent] =
    useState<TNotificationTime>(null);

  useEffect(() => {
    (async function fetchMarkdown() {
      try {
        const text = await fetch(`/assets/book/${topic.path}.md`);
        const respText = await text.text();

        // Простейшая проверка, чтобы убедиться, что это markdown, а не HTML
        // (на Android возвращается HTML, если файл не найден)
        if (respText.includes('html>')) {
          throw new Error('File not found or invalid content');
        }

        if (respText) {
          setMarkdownContent(respText);
        }
      } catch (e) {
        console.log('Ошибка получения контента', e);
      }
    })();
  }, []);

  return (
    <Modal close={close}>
      <SWrapper>
        <H1Top>Урок</H1Top>
        {videos && videos.length > 1 && (
          <>
            <p>Для понимания этого урока нужно посмотреть несколько видео</p>
            <p>Не бойся, они все короткие!</p>
          </>
        )}
        {videos && videos.length > 0 && (
          <>
            {videos.map((video, index) => {
              return (
                <SVideo key={index}>
                  <SIframe
                    width="90%"
                    height="150px"
                    src={video}
                    title={topic.name}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </SVideo>
              );
            })}

            <SGrateful>
              Благодарим Юлию Сопильняк за прекрасный курс! <br /> Сайт Юлии:{' '}
              <a href="https://serblang.ru">https://serblang.ru</a>
            </SGrateful>
            <br />
          </>
        )}
        {markdownContent && (
          <>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {markdownContent}
            </ReactMarkdown>
          </>
        )}
        <ButtonStyled
          square={false}
          size={'l'}
          fontSize={18}
          text={'Перейти к упражнениям'}
          onClick={close}
        />
        <SCat>
          <CatTeacherImg />
        </SCat>
      </SWrapper>
    </Modal>
  );
}

const ButtonStyled = styled(Button)`
  font-size: 10px;
`;

const SGrateful = styled.div`
  font-size: 10px;
`;

const SIframe = styled.iframe`
  box-shadow: var(--polako-shadow-thick);
`;

const SVideo = styled.div`
  margin-bottom: 20px;
`;
const SWrapper = styled.div`
  padding-bottom: 150px;
`;

const SCat = styled.div`
  position: absolute;
  width: 182px;
  right: 0;
  bottom: -5px;
`;

import styled from 'styled-components';
import { FC, ReactNode } from 'react';

type TProps = {
  title: ReactNode;
  children: ReactNode;
};

export const SubscriptionContent: FC<TProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <SWrappper {...props}>
      <STitle>{title}</STitle>
      <div>{children}</div>
    </SWrappper>
  );
};

const SWrappper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: var(--polako-white);
  box-shadow: var(--polako-shadow-minimal);
  border: 1px solid var(--polako-black);
  width: 95%;
  margin: 0 auto;

  position: relative;
  z-index: 2;
`;

const STitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--polako-black);
`;

export const getSubscriptionExpiringDateStr = (
  expireDate: number | undefined
) => {
  if (!expireDate) return '';

  const date = new Date(expireDate);

  return date.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

import { useEffect, useState } from 'react';
import { Glassfy, GlassfySku } from 'capacitor-plugin-glassfy';
import * as Sentry from '@sentry/react';
import { useShowCatForMsNew } from '../../../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
import { useIOSSetAccessToAllPermissions } from '../../../hooks/billing/useIOSSetAccessToAllPermissions';

type TProps = {
  close: () => void;
};

export const useSubscribeModalIos = ({ close }: TProps) => {
  const { showCat } = useShowCatForMsNew();
  const [selected, setSelected] = useState<GlassfySku | null>(null);
  const [donationSKUs, setDonationSKUs] = useState<GlassfySku[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successResult, setSuccessResult] = useState(false);

  const { setBillingAccessToAllPermissions: setAccessIOS } =
    useIOSSetAccessToAllPermissions();

  useEffect(() => {
    setIsLoading(true);
    Glassfy.offerings()
      .then((offerings) => {
        console.log('offerings', offerings);
        if (!offerings?.all) return;

        offerings.all.find((o) => {
          if (o.offeringId === 'subscription_price_1') {
            setDonationSKUs(o.skus);
            setSelected(o.skus[0]);
          }
        });
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
        showCat({
          text: 'Не получилось загрузить цены, сорри.',
          textSub: 'Какая-то фигня :(',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const buy = async () => {
    if (!selected) return;

    try {
      setIsLoading(true);

      const res = await Glassfy.purchaseSku({
        sku: selected,
      });

      setAccessIOS(res?.permissions);

      setSuccessResult(true);
      setIsLoading(false);
      close();
    } catch (e) {
      showCat({
        text: 'Не получилось оплатить',
        textSub: 'Какая-то фигня :(',
      });

      console.error(e);
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  return {
    donationSKUs,
    isLoading,
    successResult,
    selected,
    setSelected,
    buy,
  };
};

// @ts-ignore
import CatCelebrates from '../../assets/images/cat-celebrates.svg';
// @ts-ignore
import LogoWithSub from '../../assets/images/text-with-sub.svg';

import {
  CloseButtonStyled,
  SBottom,
  SCatWrapper,
  SContentWrapper,
  SHeader,
  SLeftSide,
  SPaddingsWrapperStyled,
} from './styles';
import { DaysInRawText } from '../../components/DaysInRaw/DaysInRawText';
import { DaysInRawNumbers } from '../../components/DaysInRaw/DaysInRawNumbers';
import { Address } from '../../components/Address';
import { IonRouterLink, useIonViewDidEnter } from '@ionic/react';
import { useDaysInRawStat } from '../../components/DaysInRaw/useDaysInRawStat';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useHideTabs } from '../../hooks/useHideTabs';
import { Analytics } from '../../analytics';

export const Intro = () => {
  const daysInRow = useDaysInRawStat();

  useIonViewDidEnter(() => {
    Analytics.trackDaysInRaw(daysInRow);
    Analytics.setCurrentScreen('Intro');
  }, []);

  useHideTabs();

  if (daysInRow === undefined) {
    return null;
  }

  if (daysInRow === null || daysInRow === 0) {
    return <Redirect to="/learn" />;
  }

  return (
    <>
      <SPaddingsWrapperStyled>
        <SHeader>
          <IonRouterLinkStyled routerLink="/learn">
            <CloseButtonStyled />
          </IonRouterLinkStyled>
          <LogoWithSub />
        </SHeader>
        <SContentWrapper>
          <SLeftSide>
            <DaysInRawText></DaysInRawText>
            <DaysInRawNumbers daysInRow={daysInRow}></DaysInRawNumbers>
          </SLeftSide>
          <SCatWrapper>
            <CatCelebrates />
          </SCatWrapper>
        </SContentWrapper>
        <SBottom>
          <Address />
          <IonRouterLink routerLink="/learn">
            <CloseButtonStyled />
          </IonRouterLink>
        </SBottom>
      </SPaddingsWrapperStyled>
    </>
  );
};

const IonRouterLinkStyled = styled(IonRouterLink)`
  align-self: flex-end;
`;

import styled from 'styled-components';
import { SIconWrapper } from '../../../components/styles';
import { FC } from 'react';

type StatCardProps = {
  title: string | number;
  desc: string;
};

export const StatCard: FC<StatCardProps> = ({ title, desc }) => {
  return (
    <SScore>
      <SIconWrapperStyled size={'l'} $color="yellow">
        {title}
      </SIconWrapperStyled>
      <SScoreNumber> {desc}</SScoreNumber>
    </SScore>
  );
};

const SScore = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  color: var(--polako-black);
  font-weight: 700;
  text-align: center;

  width: fit-content;
  align-items: center;
`;

const SScoreNumber = styled.div`
  font-size: 10px;
  padding-top: 5px;
`;

const SIconWrapperStyled = styled(SIconWrapper)`
  padding-left: 10px;
  padding-right: 10px;
  height: initial;
`;

import styled from 'styled-components';

export const MainInfo = () => {
  return (
    <SProfileCardsWrapper>
      {/*<H1Top>Polako — тренажёр Сербского языка</H1Top>*/}
      <p>
        Polako APP - приложение для изучения и тренировки сербской грамматики.
        Большинство правил совпадают и для черногорского, и для хорватского
        языков. Приложение создано, чтобы вы могли отточить и довести до
        автоматизма языковые конструкции.
      </p>
      <p>
        Здесь намеренно нет тренировки слов, по двум причинам. Во-первых, для
        этого уже существует несколько приложений, такие как Drops, Ling,
        DuoCards. Во-вторых, Polako APP ориентировано на русскоговорящих, и
        значения многих слов можно понять из контекста.
      </p>
      <p>
        Я работал над этим приложением чуть меньше года, каждый день, и буду
        очень рад если вы оцените его в магазинах и поставите хорошую оценку!
      </p>
      <p>
        Если у вас есть предложения, замечания, или просто хотите поболтать -
        смело пишите мне в телеграм{' '}
        <a href={'https://t.me/polakoapp'}>@polakoapp</a> или на почту{' '}
        <a href={'polakoapp1@gmail.com'}>polakoapp1@gmail.com</a>
      </p>
    </SProfileCardsWrapper>
  );
};

const SProfileCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--polako-black);
`;

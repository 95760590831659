// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
console.log('mode', import.meta.env.MODE);

export const firebaseConfig = {
  apiKey: 'AIzaSyAyMGvRcRX5edvRuiohyJrBhA0AD-inM8w',
  authDomain: 'polako-app-prod.firebaseapp.com',
  projectId: 'polako-app-prod',
  storageBucket: 'polako-app-prod.appspot.com',
  messagingSenderId: '837135382058',
  appId: '1:837135382058:web:c8bb48283c6ff7f24e7553',
  measurementId: 'G-GF68SKBG55',
};
// export const firebaseConfig = import.meta.env.MODE === 'development'
//     ? {
//         apiKey: 'AIzaSyAyMGvRcRX5edvRuiohyJrBhA0AD-inM8w',
//         authDomain: 'polako-app-prod.firebaseapp.com',
//         projectId: 'polako-app-prod',
//         storageBucket: 'polako-app-prod.appspot.com',
//         messagingSenderId: '837135382058',
//         appId: '1:837135382058:web:c8bb48283c6ff7f24e7553',
//         measurementId: 'G-GF68SKBG55',
//       }
//     : {
//         apiKey: 'AIzaSyCoSZEiqXDFivnrFOII7FBGFeLl8eXf3nA',
//         authDomain: 'polako-app-dev.firebaseapp.com',
//         databaseURL:
//           'https://polako-app-dev-default-rtdb.europe-west1.firebasedatabase.app',
//         projectId: 'polako-app-dev',
//         storageBucket: 'polako-app-dev.appspot.com',
//         messagingSenderId: '552674705216',
//         appId: '1:552674705216:web:03caa65b4d68403f5844a5',
//         measurementId: 'G-JVXHKQ2KHS',
//       };

import { Glassfy, GlassfySku } from 'capacitor-plugin-glassfy';

const initializeApp = async () => {
  try {
    await Glassfy.initialize({
      apiKey: 'c3a07511318647f9b3c24f337d3777b5',
      watcherMode: false,
    });
  } catch (e) {
    // initialization error
    console.log(e);
  }
};

const connectCustomSubscriber = async (subscriberId: string) => {
  await Glassfy.connectCustomSubscriber({
    subscriberId: subscriberId,
  });
};

const restorePurchases = async () => {
  return Glassfy.restorePurchases();
};

const getPermissions = async () => {
  return Glassfy.permissions();
};

const getOfferings = async () => {
  return Glassfy.offerings();
};

const purchaseSku = async (sku: GlassfySku) => {
  return Glassfy.purchaseSku({
    sku,
  });
};

export const GlassfyFunctions = {
  initializeApp,
  connectCustomSubscriber,
  restorePurchases,
  getPermissions: getPermissions,
  getOfferings: getOfferings,
  purchaseSku,
};

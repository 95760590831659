import './Game.css';
import { useGame } from './hooks/useGame';
import { TTopic } from './types';
import React, { useEffect, useState } from 'react';
import { Book } from './features/Book';
import iconBook from '../../assets/icons/book.svg?inline';
import iconDisike from '../../assets/icons/dislike.svg?inline';
import iconBulb from '../../assets/icons/bulb.svg?inline';
import iconBackspace from '../../assets/icons/backspace.svg?inline';
import { MainButton } from './components/MainButton';
import { Feedback } from './features/Feedback';
import { BackButton } from '../../components/BackButton';
import { Button } from '../../components/Button';

import { WordButton } from './components/WordButton';
import { IconButton } from '../../components/IconButton';
import { Tip } from './features/Tip';
import { Scores } from './features/Scores';
import { Header } from '../../components/Header';

import styled, { css, keyframes } from 'styled-components';
import { useShakeAnimation } from './hooks/useShake';
import { clearWordString } from './utils/cleadrWordString';
import { useIonViewDidEnter } from '@ionic/react';
import { useHideTabs } from '../../hooks/useHideTabs';
import { Analytics } from '../../analytics';
import { GameSignIn } from './features/GameSignIn';
import { useRuntimeStore } from '../../initApp';
import { useShowSignIn } from './hooks/useShowSignIn';
import { NotificationModal } from '../../components/NotificationsSetup/NotificationsSetup';

console.log('iconBook', iconBook);

type TProps = {
  topic: TTopic;
};

export const Game = ({ topic }: TProps) => {
  const {
    isCorrect,
    userSelectedWordsArr,
    readyClickHandler,
    nextClickHandler,
    currentSentence,
    currentVideos,
    wordsToChooseFrom,
    wordClickHandler,
    alreadyPressedIndexes,
    showTipClickHandler,
    userSentenceStr,
    undoClickHandler,
    isReady,
    tipText,
    setTipText,
    lessonStat,
    getIfShouldOpenBook,
    isOnlyButtonMode,
  } = useGame(topic);

  const isNotificationsWindowOpened = useRuntimeStore(
    (s) => s.isNotificationsWindowOpened
  );
  const setIsNotificationsWindowOpened = useRuntimeStore(
    (s) => s.setIsNotificationsWindowOpened
  );

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('Game');
  }, []);

  useHideTabs();

  const [showBook, setShowBook] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const getAnswerPlaceholder = () => {
    if (!userSentenceStr || userSentenceStr === '_') {
      return '';
    }

    return userSentenceStr.replace(/_+/g, '____');
  };

  const [shouldShake, triggerShake] = useShakeAnimation();

  useEffect(() => {
    getIfShouldOpenBook().then((isLessonOpenedFirstTime) => {
      if (isLessonOpenedFirstTime) {
        setShowBook(true);
      }
    });
  }, [topic.path]);

  const { user } = useRuntimeStore();

  useShowSignIn({
    isAnonymous: user?.isAnonymous ?? true,
    lessonStat,
    setShowSignIn,
  });

  if (!isReady) {
    return null;
  }

  return (
    <>
      {isNotificationsWindowOpened && (
        <NotificationModal
          close={() => setIsNotificationsWindowOpened(false)}
        />
      )}
      {/*<EncouragingCat*/}
      {/*  isShown={isUnblockCatShown}*/}
      {/*  text={unblockCatShownText}*/}
      {/*  side="right"*/}
      {/*/>*/}
      {showBook && (
        <Book
          close={() => {
            setShowBook(false);
            triggerShake();
          }}
          topic={topic}
          videos={currentVideos}
        />
      )}
      {showSignIn && (
        <GameSignIn
          onSuccess={() => {
            setShowSignIn(false);
          }}
          close={() => {
            setShowSignIn(false);
          }}
        />
      )}
      <Feedback showModal={showFeedback} setShowModal={setShowFeedback} />
      {Boolean(tipText) && (
        <Tip text={tipText} close={() => setTipText(null)} />
      )}
      <Header>
        <BackButton text={topic.name}></BackButton>
        <SHeaderButtons>
          <IconButtonStyled
            size={'m'}
            $shouldShake={shouldShake}
            icon={iconBook}
            fontSize={16}
            onClick={() => {
              setShowBook(true);
            }}
          ></IconButtonStyled>

          <IconButtonStyled
            size={'m'}
            icon={iconDisike}
            onClick={() => setShowFeedback(true)}
          ></IconButtonStyled>
        </SHeaderButtons>
      </Header>
      <SContent>
        <SWrap>
          <SLeft>
            <SCondition>{currentSentence?.native}</SCondition>

            {currentSentence?.extra && (
              <SConditionExtra>({currentSentence?.extra})</SConditionExtra>
            )}

            {!isOnlyButtonMode && <SAnswer>{getAnswerPlaceholder()}</SAnswer>}
          </SLeft>
          <SRight>
            <Scores lessonStat={lessonStat} />
          </SRight>
        </SWrap>

        <div>
          <SPuzzle>
            {(wordsToChooseFrom || []).map((word, index) => {
              const isPressed = alreadyPressedIndexes.includes(index);
              const clickHandler = () =>
                wordClickHandler(word, isPressed, index);

              return (
                <WordButton
                  onClick={clickHandler}
                  isPressed={isPressed}
                  key={index}
                >
                  {clearWordString(word)}
                </WordButton>
              );
            })}
          </SPuzzle>

          {/*<Footer>*/}
          <SMainButtons>
            <Button
              size={'l'}
              onClick={showTipClickHandler}
              color={'yellow'}
              icon={iconBulb}
              fontSize={30}
              square
            />

            <SMainButton>
              <MainButton
                isCorrect={isCorrect}
                userSelectedWordsArr={userSelectedWordsArr}
                readyClickHandler={readyClickHandler}
                handleNext={nextClickHandler}
              />
            </SMainButton>

            <Button
              size={'l'}
              onClick={undoClickHandler}
              color={'yellow'}
              icon={iconBackspace}
              fontSize={30}
              square
            />
          </SMainButtons>
        </div>
      </SContent>
    </>
  );
};

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--ion-background-color);

  padding: var(--polako-padding);
  padding-bottom: var(--polako-padding-bottom);
`;

const SMainButton = styled.div`
  flex: 1;
`;

const SWrap = styled.div`
  display: flex;
  gap: 20px;
`;

const SLeft = styled.div`
  flex: 1;
`;

const SRight = styled.div``;

const SMainButtons = styled.div`
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 16px;
`;

const shake = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
`;

const IconButtonStyled = styled(IconButton)<{ $shouldShake?: boolean }>`
  height: 32px;
  width: 32px;

  ${(props) =>
    props.$shouldShake &&
    css`
      animation: ${shake} 0.5s;
    `}
`;

const SHeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const SPuzzle = styled.div`
  display: inline-flex;
  gap: 14px;
  flex-wrap: wrap;
  position: relative;
`;

const SCondition = styled.div`
  font-weight: 700;
  margin-bottom: 10px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  line-height: 130%; /* 18.2px */
`;

const SConditionExtra = styled(SCondition)`
  font-size: 12px;
`;

const SAnswer = styled.div`
  color: var(--polako-black);
  border: 1px solid #000;
  min-height: 100px;
  background-color: white;
  display: flex;
  padding: 14px 20px;
  gap: 10px;
  box-shadow: var(--polako-shadow);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
`;

import { SIconWrapper } from './styles';
import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { Button, TButtonProps } from './Button';

export type TIconButton = TButtonProps & {
  onClick?: () => void;
  icon: string;
  color?: string;
  isDisabled?: boolean;
  fontSize?: number;
  className?: string;
};

export const IconButton = ({
  onClick,
  icon,
  color = 'white',
  isDisabled = false,
  fontSize = 14,
  size = 'm',
  className,
  ...props
}: TIconButton) => {
  // return (
  //   <SIconWrapperStyled
  //     as="button"
  //     disabled={isDisabled}
  //     $square={true}
  //     $color={color}
  //     $fontSize={fontSize}
  //     onTouchStart={onClick}
  //     onClick={onClick}
  //     className={className}
  //     {...props}
  //   >
  //     <IonIcon src={icon}></IonIcon>
  //   </SIconWrapperStyled>
  // );
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      square={true}
      color={color}
      fontSize={fontSize}
      size={size}
      className={className}
      {...props}
    >
      <IonIcon src={icon}></IonIcon>
    </Button>
  );
};

const SIconWrapperStyled = styled(SIconWrapper)`
  color: var(--polako-black);
  font-weight: 700;
  padding: 2px;
  height: 28px;
  width: 28px;

  &:disabled {
    background-color: var(--polako-gray);
    box-shadow: none;
  }
`;

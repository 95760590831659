import { TTask } from '../types';

// когда в иностранном варианте у нас только _, и больше нет других слов
export const getIsOnlyGapMode = (task: TTask | null) => {
  if (!task) {
    return undefined;
  }

  return task.foreign === '_';
};

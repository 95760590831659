import { useEffect, useState } from 'react';
import { TGetLesson, TStorageLesson, TTopic } from '../../types';
import {
  decrementScoreBasic,
  incrementBasic,
  incrementScoreBasic,
} from './utils';

type TProps = {
  getLesson: TGetLesson;
  topic: TTopic;
};

export const useLessonStat = ({ getLesson, topic }: TProps) => {
  const [lessonStat, setLessonStat] = useState<TStorageLesson | null>(null);

  useEffect(() => {
    (async () => {
      const state = await getLesson(topic.path);
      console.log('topic.path', topic.path);

      setLessonStat(state);
    })();
  }, [topic.path]);

  const incrementCorrect = () => {
    setLessonStat((stat) => {
      if (stat === null) return null;

      return {
        ...stat,
        correct: incrementBasic(stat.correct),
      };
    });
  };

  const incrementWrong = () => {
    setLessonStat((stat) => {
      if (stat === null) return null;

      return {
        ...stat,
        wrong: incrementBasic(stat.wrong),
      };
    });
  };

  const incrementScore = (): number | undefined => {
    if (lessonStat === null) return;

    const newScore = incrementScoreBasic(lessonStat.score);

    setLessonStat((stat) => {
      if (stat === null) return null;
      return {
        ...stat,
        score: newScore,
      };
    });

    return newScore;
  };

  const decrementScore = () => {
    setLessonStat((stat) => {
      if (stat === null) return null;

      return {
        ...stat,
        score: decrementScoreBasic(stat.score),
      };
    });
  };

  return {
    lessonStat,
    incrementCorrect,
    incrementWrong,
    incrementScore,
    decrementScore,
  };
};

import styled, { css } from 'styled-components';
import { IonButton } from '@ionic/react';

const SIZES = {
  s: 24,
  sm: 28,
  ms: 30,
  m: 32,
  l: 48,
  unlimited: undefined,
};

const FONT_SIZES = {
  s: 10,
  sm: 12,
  ms: 12,
  m: 14,
  l: 16,
} as const;

type TSize = keyof typeof FONT_SIZES | undefined;
export type TFontSize = keyof typeof SIZES;

const highGetter = ({ $size }: { $size?: TSize }) =>
  ($size ? SIZES[$size] : SIZES.s) + 'px';

const fontSizeGetter = ({ $fontSize, $size }: THeightProps) => {
  if ($fontSize) return $fontSize + 'px';
  return ($size ? FONT_SIZES[$size] : SIZES.s) + 'px';
};

type TWidthProps = {
  $square?: boolean;
  $wide?: boolean;
  $size?: TSize;
  $fontSize?: number;
};
type THeightProps = {
  $fontSize?: number;
  $size?: TSize;
};
type TWidthGetter = (p: TWidthProps) => string;

const widthGetter: TWidthGetter = (p) => {
  if (p.$square) {
    return highGetter({ $size: p.$size });
  }
  if (p.$wide) {
    return '100%';
  }
  return 'fit-content';
};

const minWidthGetter: TWidthGetter = (p) => {
  if (p.$square) {
    return highGetter({ $size: p.$size });
  }
  return 'initial';
};

export type SIconProps = { size?: TSize } & TWidthProps &
  THeightProps & { $color?: string };

export const SIconWrapper = styled.div<SIconProps>`
  // TODO: подумать точно ли нужна тень
  box-shadow: var(--polako-shadow);
  color: var(--polako-black);
  font-weight: 700;
  text-align: center;
  height: ${highGetter};
  width: ${widthGetter};
  min-width: ${minWidthGetter};
  border: var(--polako-border);
  background-color: ${(p) => css`var(--polako-${p.$color || 'white'})`};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: ${fontSizeGetter};

  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`;

type TIconWrapperButton = { $size?: TSize } & TWidthProps &
  THeightProps & { $color?: string };

export const SIconWrapperButton = styled(IonButton)<TIconWrapperButton>`
  --background: ${(p) => css`var(--polako-${p.$color || 'white'})`};
  //--background-hover: #9ce0be;
  //--background-activated: ${(p) => css`var(--polako-${p.$color || 'white'})`};
  //--background-focused: #88f4be;

  --color: var(--polako-black);
  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: var(--polako-shadow);
  &:hover,
  &:active {
    opacity: 1;
  }

  &::part(native) {
    height: ${highGetter};
    width: ${widthGetter};
    min-width: ${minWidthGetter};
    padding: 5px;
    min-height: ${highGetter};
    position: relative;
    &:active {
      --box-shadow: none;
      right: -2px;
      top: 2px;
    }
  }

  width: ${widthGetter};
  min-width: ${minWidthGetter};
  min-height: initial;
  margin: 0;
  font-size: ${fontSizeGetter};
`;

export const SIconText = styled.span<TWidthProps>`
  color: var(--ion-color-primary);
  font-size: ${(p) => (p.$fontSize || 10) + 'px'};
  font-style: normal;
  font-weight: 700;
  font-feature-settings: 'case' on;
  width: ${(p) => (p.$wide ? '100%' : 'auto')};
`;

export const SIconDesc = styled.span`
  color: var(--ion-color-primary);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
`;

export const SIconAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`;

export const SPagePaddingsWrapper = styled.div`
  padding: var(--polako-padding);
  height: 100%;
`;

export const bordersCss = css<{ $color: string }>`
  --background: ${(p) => css`var(--polako-${p.$color || 'white'})`};
  --color: var(--polako-black);
  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: var(--polako-shadow);
  &:hover,
  &:active {
    opacity: 1;
  }
`;

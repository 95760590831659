import { IonAppStyled } from '../../Styles';
import { ReleaseWall } from './ReleaseWall';
import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router-dom';

import { IonReactRouter } from '@ionic/react-router';

import { Pay } from './pages/Pay';
import { EncouragingCatNew } from '../Game/components/EncouragingCat2/EncouragingCat';
import { TermsComplicated } from './pages/TermsComplicated';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SubscriptionCalculator } from './pages/Calc';

export const Landing = () => {
  if (location.pathname === '/terms') {
    return <TermsComplicated />;
  }

  if (location.pathname === '/privacy-policy') {
    return <PrivacyPolicy />;
  }

  return (
    <IonAppStyled>
      <EncouragingCatNew />
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="*" exact={true} component={ReleaseWall}></Route>
          <Route path="/pay" exact={true} component={Pay}></Route>
          <Route
            path="/calc"
            exact={true}
            component={SubscriptionCalculator}
          ></Route>
        </IonRouterOutlet>
      </IonReactRouter>

      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/96552998"
            style={{
              position: 'absolute',
              left: '-9999px',
            }}
            alt=""
          />
        </div>
      </noscript>
    </IonAppStyled>
  );
};

import { useEffect, useState } from 'react';
import { CatPhrases } from './CatPhrases';
import { PHRASES } from './phrases';

import {
  CAT_ON_SCREEN,
  SHOW_CAT_EVERY_CORRECT_ANSWER,
} from '../../../../constants';
import { useShowCatForMsNew } from '../../components/EncouragingCat2/useShowCatForMsNew';

const catPhrasesApp = new CatPhrases(PHRASES);

export const useEncouragingCat = () => {
  const { showCat } = useShowCatForMsNew();
  const [correctAnswersStreak, setCorrectAnswersStreak] = useState(0);

  useEffect(() => {
    if (correctAnswersStreak === SHOW_CAT_EVERY_CORRECT_ANSWER) {
      showCat({
        text: catPhrasesApp.getRandomPhrase(),
        duration: CAT_ON_SCREEN,
      });
      setCorrectAnswersStreak(0);
    }
  }, [correctAnswersStreak]);

  const handleStreakAnswer = (isCorrect: boolean) => {
    if (isCorrect) {
      setCorrectAnswersStreak((prevStreak) => prevStreak + 1);
    } else {
      setCorrectAnswersStreak(0);
    }
  };

  return { handleStreakAnswer };
};

import React, { FC } from 'react';
import styled from 'styled-components';

type BubbleTextsProps = {
  text: string;
  textSub?: string;
};

export const BubbleTexts: FC<BubbleTextsProps> = ({ text, textSub }) => {
  return (
    <SText>
      <STextMain>{text}</STextMain>
      {textSub && <STextSub>{textSub}</STextSub>}
    </SText>
  );
};

const SText = styled.div`
  color: var(--polako-black);
  width: 144px;
  line-height: 1.3;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const STextMain = styled.div`
  font-size: 20px;
  font-weight: 800;
`;

const STextSub = styled.div`
  font-size: 7px;
  color: var(--polako-gray);
  font-weight: 700;
  max-width: 85px;
`;

import { IonApp, IonContent, IonPage } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

export const PrivacyPolicy = () => {
  return (
    <IonAppStyled>
      <IonPage>
        <IonContent fullscreen>
          <h1>Политика конфиденциальности приложения Polako</h1>

          <p>
            <strong>Дата вступления в силу: 20.06.2024</strong>
          </p>

          <h2>1. Введение</h2>
          <p>
            Приложение Polako ("мы", "наше", "нас" или "Приложение") разработано
            Даниилом Кудрявцевым для помощи русскоговорящим пользователям в
            изучении сербского языка. Настоящая Политика конфиденциальности
            объясняет, как мы собираем, используем, храним и защищаем вашу
            личную информацию при использовании нашего Приложения. Используя
            Polako, вы соглашаетесь с практиками, описанными в этой Политике
            конфиденциальности.
          </p>

          <h2>2. Собираемая информация</h2>
          <h3>2.1. Информация, предоставляемая пользователем:</h3>
          <ul>
            <li>Данные учетной записи: имя, адрес электронной почты, пароль</li>
            <li>
              Профиль пользователя: возраст, уровень владения языком (если
              предоставлено)
            </li>
            <li>
              Пользовательский контент: заметки, сохраненные слова или фразы
            </li>
          </ul>

          <h3>2.2. Автоматически собираемая информация:</h3>
          <ul>
            <li>
              Данные об использовании: частота использования, время сеансов,
              прогресс обучения, результаты тестов
            </li>
            <li>
              Технические данные: тип устройства, версия операционной системы,
              уникальные идентификаторы устройства, IP-адрес
            </li>
            <li>
              Данные о производительности приложения: сбои, ошибки системы
            </li>
          </ul>

          <h3>2.3. Информация из сторонних источников:</h3>
          <p>
            Если вы регистрируетесь через сторонние сервисы (например, Google),
            мы можем получать информацию из этих источников в соответствии с их
            политиками конфиденциальности.
          </p>

          <h2>3. Как мы используем информацию</h2>
          <h3>3.1. Предоставление и улучшение услуг:</h3>
          <ul>
            <li>Создание и управление вашей учетной записью</li>
            <li>Предоставление персонализированного обучающего контента</li>
            <li>Улучшение алгоритмов обучения и функциональности приложения</li>
          </ul>

          <h3>3.2. Коммуникация:</h3>
          <ul>
            <li>
              Отправка уведомлений о новых функциях, обновлениях или изменениях
              в нашей политике
            </li>
            <li>Ответы на ваши запросы и предоставление поддержки</li>
          </ul>

          <h3>3.3. Аналитика и исследования:</h3>
          <ul>
            <li>
              Анализ поведения пользователей для улучшения пользовательского
              опыта
            </li>
            <li>Проведение исследований и разработка новых функций</li>
          </ul>

          <h3>3.4. Безопасность:</h3>
          <ul>
            <li>
              Обнаружение, предотвращение и расследование потенциальных
              нарушений безопасности
            </li>
          </ul>

          <h2>4. Хранение и защита данных</h2>
          <h3>4.1. Хранение данных:</h3>
          <p>
            Мы используем Firebase (Firestore Database, Firebase Analytics,
            Firebase Authentication) для хранения и обработки данных.
          </p>

          <h3>4.2. Меры безопасности:</h3>
          <ul>
            <li>Шифрование данных при передаче и хранении</li>
            <li>Регулярное обновление систем безопасности</li>
            <li>
              Ограничение доступа к личной информации только уполномоченным
              сотрудникам
            </li>
          </ul>

          <h3>4.3. Срок хранения:</h3>
          <p>
            Мы храним вашу личную информацию до тех пор, пока ваша учетная
            запись активна или пока это необходимо для предоставления услуг. Вы
            можете запросить удаление вашей информации в любое время.
          </p>

          <h2>5. Передача данных третьим лицам</h2>
          <p>5.1. Мы не продаем вашу личную информацию третьим лицам.</p>

          <p>
            5.2. Мы можем передавать информацию следующим категориям
            получателей:
          </p>
          <ul>
            <li>
              Поставщики услуг: компании, которые помогают нам в работе
              приложения (например, хостинг, аналитика)
            </li>
            <li>
              Правоохранительные органы: если это требуется по закону или для
              защиты наших прав
            </li>
          </ul>

          <p>5.3. Передача анонимных данных:</p>
          <p>
            Мы можем передавать анонимные или агрегированные данные третьим
            лицам для аналитических или исследовательских целей.
          </p>

          <h2>6. Ваши права и выбор</h2>
          <h3>6.1. Вы имеете право:</h3>
          <ul>
            <li>Получить доступ к вашей личной информации</li>
            <li>Исправить неточную или неполную информацию</li>
            <li>Удалить вашу личную информацию</li>
            <li>Ограничить обработку вашей информации</li>
            <li>
              Получить копию ваших данных в структурированном, машиночитаемом
              формате
            </li>
          </ul>

          <p>
            6.2. Для реализации этих прав, пожалуйста, свяжитесь с нами по
            указанному ниже адресу.
          </p>

          <p>
            6.3. Вы можете отказаться от получения рекламных сообщений, изменив
            настройки в приложении или следуя инструкциям в полученных
            сообщениях.
          </p>

          <h2>7. Конфиденциальность детей</h2>
          <p>
            Наше Приложение не предназначено для детей младше 13 лет. Мы
            сознательно не собираем личную информацию от детей младше 13 лет.
            Если вы считаете, что мы могли собрать информацию о ребенке младше
            13 лет, пожалуйста, свяжитесь с нами.
          </p>

          <h2>8. Изменения в политике конфиденциальности</h2>
          <p>
            8.1. Мы можем время от времени обновлять эту политику. Дата
            последнего обновления будет указана в начале документа.
          </p>

          <p>
            8.2. Мы уведомим вас о любых существенных изменениях через
            приложение или по электронной почте.
          </p>

          <p>
            8.3. Продолжая использовать Приложение после изменений, вы
            соглашаетесь с обновленной политикой.
          </p>

          <h2>9. Международная передача данных</h2>
          <p>
            Ваша информация может быть передана и обработана в странах, отличных
            от вашей страны проживания, где расположены наши серверы или
            работают наши поставщики услуг. Эти страны могут иметь законы о
            защите данных, отличающиеся от законов вашей страны.
          </p>

          <h2>10. Сторонние ссылки и сервисы</h2>
          <p>
            Наше Приложение может содержать ссылки на сторонние веб-сайты или
            сервисы. Мы не несем ответственности за политики конфиденциальности
            или практики этих сторонних сайтов или сервисов.
          </p>

          <h2>11. Контактная информация</h2>
          <p>
            Если у вас есть вопросы, комментарии или запросы, касающиеся этой
            Политики конфиденциальности или обработки ваших данных, пожалуйста,
            свяжитесь с нами:
          </p>

          <p>
            Daniil Kudriavtsev
            <br />
            polakoapp1@gmail.com
          </p>

          <h2>12. Согласие</h2>
          <p>
            Используя наше Приложение, вы соглашаетесь с нашей Политикой
            конфиденциальности. Если вы не согласны с этой политикой,
            пожалуйста, не используйте наше Приложение.
          </p>
        </IonContent>
      </IonPage>
    </IonAppStyled>
  );
};

const IonAppStyled = styled(IonApp)`
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  color: white;
`;

import styled from 'styled-components';
import { CloseButton } from '../../components/CloseButton';

export const CloseButtonStyled = styled(CloseButton)`
  align-self: end;

  height: 38px;
  width: 38px;
  min-height: 38px;
  min-width: 38px;

  &::part(native) {
    height: 38px;
    width: 38px;
    min-height: 38px;
    min-width: 38px;
  }
`;

export const SPaddingsWrapperStyled = styled.div`
  padding: var(--polako-padding);
  padding-top: var(--polako-padding-top);
  padding-bottom: var(--polako-padding-bottom);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  height: 100vh;
`;

export const SDays = styled.div`
  font-size: 60px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  line-height: 1;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black,
    3px 3px 0px var(--polako-yellow),
    6px 6px 0px var(--polako-black);
`;

export const SDaysInRow = styled.div`
  color: var(--polako-white, #fff);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  font-style: normal;
  text-transform: uppercase;
`;

export const SAddress = styled.div`
  padding: 8px 12px;
  border: 1px solid black;
  width: fit-content;
  background-color: yellow;
  color: black;
  font-weight: 900;
  height: 38px;
`;

export const SContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SBottom = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: start;
`;

export const SDaysInRowOuter = styled.div``;

export const SHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SDaysOuter = styled.div``;

export const SLeftSide = styled.div`
  min-width: 50%;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const SCatWrapper = styled.div`
  min-width: 280px;
  position: relative;
`;

import React, { FC } from 'react';
import styled from 'styled-components';
import Star from '../../../assets/images/Star.svg';
import Heart1 from '../../../assets/images/heart1.svg';
import Heart2 from '../../../assets/images/heart2.svg';

import { SDays } from '../../../pages/Intro/styles';
import { CatAngleWithBubble } from '../components/CatAngleWithBubble';
import { CatFireworkWithBubble } from '../components/CatCelebratesBubble';
import { SubscriptionContent } from '../components/SubscriptionContent';
import { BuyRadio } from '../../BuyRadio/BuyRadio';
import { Modal } from '../../Modal/Modal';
import { Typo } from '../../Typo';
import { Button } from '../../Button';
import { useSubscribeModalAndroid } from './useSubscribeModalAndroid';
import { IonSpinner } from '@ionic/react';

import { useHideShow } from '../../../hooks/useHideShow';
import { FlowerPoint } from '../../FlowerPoint';

const PERIODS = {
  P1M: '1 месяц',
  P6M: '6 месяцев',
};
//
// const getText = (sku: GlassfySku | null) => {
//   if (!sku) return '';
//
//   const { period } = sku.product;
//
//   return `${PERIODS[period as TPeriods]}`;
// };
//
// const getTextLine2 = (sku: GlassfySku | null) => {
//   if (!sku) return '';
//
//   return `${sku.product.price} ${sku.product.currencyCode}`;
// };

// type TPeriods = keyof typeof PERIODS;

type TProps = { close: () => void };

export const SubscribeModalAndroid: FC<TProps> = ({ close }) => {
  const [showWhyForMoney, _, isShownWhyForMoney] = useHideShow(false);

  const {
    buy,
    successResult,
    skus,
    isLoading,
    selected,
    setSelected,
    getText,
    getTextLine2,
  } = useSubscribeModalAndroid({ close });

  console.log('skus', skus);

  return (
    <ModalStyled close={close}>
      <SWrapper>
        <SKletka />
        <SHeaderTexts>
          <SHeader1>Подписка</SHeader1>
          <SHeader2>на уроки</SHeader2>
        </SHeaderTexts>

        <SHeaderCats>
          <Heart1Styled />
          <Heart2Styled />
          {/*<Heart3Styled />*/}
          {/*<Heart4Styled />*/}
          <SubscriptionContent title="Почему платно?">
            <Typo.Text>
              Потому что я работаю над приложением уже полтора года, ежедневно,
              потратил кучу сил и денег. Но мне нравится 😁❤️ И хочется
              продолжать.
              <br />
              <br />
            </Typo.Text>

            {isShownWhyForMoney && (
              <>
                <br />
                <Typo.Text>
                  Изначально я хотел сделать бесплатное приложение, по-быстрому
                  и "на коленке", но альтруистические идеалы разбились о
                  реальность
                </Typo.Text>
                <br />
                <FlowerPoint>
                  <Typo.Text>
                    надо платить эпплу и гуглу, чтобы просто находиться в сторе
                    💰🤯
                  </Typo.Text>
                </FlowerPoint>
                <FlowerPoint>
                  <Typo.Text>платить преподавателям за помощь 🙏</Typo.Text>
                </FlowerPoint>
                <FlowerPoint>
                  <Typo.Text>
                    платить дизайнеру за все кнопочки и тенюшечки
                  </Typo.Text>
                </FlowerPoint>
                <FlowerPoint>
                  <Typo.Text>
                    красноглазить ночами после работы (я до этого никогда не
                    делал приложений) 😰
                  </Typo.Text>
                </FlowerPoint>
                <FlowerPoint>
                  <Typo.Text>как-то мотивироваться развивать проект </Typo.Text>
                </FlowerPoint>
                <br />
                <Typo.Text>
                  Спасибо всем кто поддержал и пишет добрые слова! Если бы этого
                  не было, я бы уже давно сдулся ❤️
                </Typo.Text>
              </>
            )}
            {!isShownWhyForMoney && (
              <Typo.Text>
                <SPseudoLink onClick={showWhyForMoney}>
                  Чуть подробнее
                </SPseudoLink>
              </Typo.Text>
            )}
          </SubscriptionContent>
          <SAbsoluteStuff>
            <SStar>
              <Star />
            </SStar>

            <CatAngleWithBubble
              side="right"
              text="30+"
              textSub="тем грамматики (будет больше)"
            />

            <CatAngleWithBubble
              side="left"
              text="Ново!"
              textSub="Регулярно обновления"
            />

            <CatFireworkWithBubble
              text="Скидка"
              textSub="потом чуток подорожает"
            />
          </SAbsoluteStuff>
        </SHeaderCats>

        {successResult && <div>Спасибо!</div>}

        <SubscriptionContentStyled title="Купить подписку на доступ ко всем урокам">
          {isLoading && (
            <SLoader>
              <IonSpinner />
            </SLoader>
          )}

          <br />

          {!isLoading && (
            <SButtons>
              {(skus || []).map((sku, key) => (
                <BuyRadio
                  text={getText(sku)}
                  textLine2={getTextLine2(sku)}
                  // extraText={sku.product.description}
                  // extraText2={sku.extravars.extraText}
                  key={key}
                  selected={selected?.id === sku.id}
                  onClick={() => {
                    setSelected(sku);
                  }}
                />
              ))}
            </SButtons>
          )}

          <br />

          <div>
            <SLink href="https://polako.app/pay">
              Хочу оплатить, но не могу через Apple, что делать?{' '}
            </SLink>
          </div>

          <br />
          <br />

          <SSmallLinks>
            <SLinkSmall href="https://polako.app/terms">
              Terms & Conditions
            </SLinkSmall>

            <SLinkSmall href="https://polako.app/privacy-policy">
              Privacy Policy
            </SLinkSmall>
          </SSmallLinks>
        </SubscriptionContentStyled>

        <br />
        <br />

        <SBuyButton>
          <Button
            text={isLoading ? '...' : 'Купить ' + getText(selected)}
            onClick={buy}
            wide={true}
            square={false}
            disabled={isLoading || !selected}
            size={'l'}
            fontSize={12}
            color={'green'}
          ></Button>
        </SBuyButton>
      </SWrapper>
    </ModalStyled>
  );
};

const SAbsoluteStuff = styled.div`
  position: relative;
  height: 510px;

  margin-top: -100px;
`;

const SLink = styled.a`
  font-size: 10px;
  padding-block: 10px;
`;

const SLinkSmall = styled.a`
  font-size: 7px;
  padding-block: 10px;
`;

const SSmallLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-end;
`;

const SPseudoLink = styled.span`
  font-size: 10px;
  text-decoration: dashed;
  border-bottom: 1px dashed black;
  cursor: pointer;
`;

const Heart1Styled = styled(Heart1)`
  position: absolute;
  top: -50px;
  left: 50px;
`;

const Heart2Styled = styled(Heart2)`
  position: absolute;
  top: -24px;
  right: 80px;
`;
// const Heart3Styled = styled(Heart3)`
//   position: absolute;
//   top: -24px;
//   right: 80px;
// `;
//
// const Heart4Styled = styled(Heart4)`
//   position: absolute;
//   top: -24px;
//   right: 80px;
// `;

const SubscriptionContentStyled = styled(SubscriptionContent)`
  padding-top: 30px;
`;

const SStar = styled.div`
  position: absolute;
  top: 0;
  left: -70px;
  z-index: -1;
`;

const ModalStyled = styled(Modal)`
  padding: 0;
  background-color: var(--polako-purple);
`;

const SBuyButton = styled.div`
  position: sticky;
  height: 80px;
  width: 100%;
  background-color: var(--polako-purple);
  z-index: 1000;
  border-top: 2px solid var(--polako-black);
  bottom: -1px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const SWrapper = styled.div`
  inset: 0;
`;

export const SKletka = styled.div`
  pointer-events: none;
  position: absolute;
  inset: 0;

  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 2px,
      transparent 1px
    ),
    linear-gradient(180deg, rgba(255, 255, 255, 0.1) 2px, transparent 1px);
  background-size: 25px 25px; /* Размер клетки */
`;

const SHeaderCats = styled.div`
  position: relative;
  overflow-x: clip;
`;

const SHeaderTexts = styled(SDays)`
  padding: 11px;
  text-shadow:
    -1px -1px 0 black,
    0px -0.5px 0 black,
    -1px 1px 0 black,
    0.6px 0.6px 0 black,
    2px 2px 0px var(--polako-yellow),
    3px 3px 0px var(--polako-black);
`;

const SHeader1 = styled.div`
  font-size: 26px;
`;

const SHeader2 = styled.div`
  font-size: 16px;
`;

const SButtons = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const SLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Header: React.FC<PropsWithChildren> = ({ children }) => {
  return <SHeader>{children}</SHeader>;
};

const SHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--polako-padding);
  justify-content: space-between;
  padding-top: var(--polako-padding-top);
  background-color: var(--ion-background-color);
`;

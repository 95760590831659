import React from 'react';
import styled, { css } from 'styled-components';
import { TSide } from '../../../pages/Game/components/EncouragingCat2/types';

import DialogBoxRight from '../../../assets/images/dialog-box-right.svg';
import DialogBoxLeft from '../../../assets/images/dialog-box-left.svg';
import CatLooksRight from '../../../assets/images/cat-looks-right-stroke.svg';
import CatLooksLeft from '../../../assets/images/cat-looks-left-stroke.svg';
import { BubbleTexts } from './BubbleTexts';

type TProps = {
  side: TSide;
  text: string;
  textSub?: string;
};

export const CatAngleWithBubble: React.FC<TProps> = ({
  side,
  text,
  textSub,
}) => {
  return (
    <SWrapper side={side}>
      {side === 'right' && <CatLooksRightStyled />}
      {side === 'left' && <CatLooksLeftStyled />}

      <SDialog side={side}>
        <STexts>
          <BubbleTexts text={text} textSub={textSub} />
        </STexts>
        {side === 'right' ? <DialogBoxRight /> : <DialogBoxLeft />}
      </SDialog>
    </SWrapper>
  );
};

const STexts = styled.div`
  position: absolute;
  top: -8px;
  left: -9px;
`;

const SDialog = styled.div<{ side: TSide }>`
  width: 128px;
  height: auto;
  top: -56px;
  color: var(--polako-black);
  font-size: 25px;
  position: absolute;

  ${({ side }) => (side === 'right' ? 'left: -76px' : 'right: -76px')};
`;

const CatLooksRightStyled = styled(CatLooksRight)`
  width: 65px;
  height: 100%;
`;

const CatLooksLeftStyled = styled(CatLooksLeft)`
  width: 65px;
`;

const SWrapper = styled.div<{ side: TSide }>`
  font-size: 10px;
  position: absolute;
  width: 65px;
  z-index: 1000;

  ${({ side }) =>
    side === 'right'
      ? css`
          right: -1px;
          left: auto;
          top: 33%;
        `
      : css`
          left: -1px;
          right: auto;
          top: 30%;
        `}
`;

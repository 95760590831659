import CatBulbImg from '../../../assets/images/cat-bulb.svg';
import { useEffect, useState } from 'react';
import { SIconWrapper } from '../../../components/styles';
import styled from 'styled-components';
import { Modal } from '../../../components/Modal/Modal';

const TIP_LASTS = 3;

type TProps = {
  text: string | null;
  close: () => void;
  autoClose?: boolean;
};

export const Tip = ({ text, close, autoClose = true }: TProps) => {
  const [timerVal, setTimerVal] = useState(TIP_LASTS);

  useEffect(() => {
    if (!autoClose) {
      return;
    }

    const timer = setTimeout(() => {
      setTimerVal((oldTimer) => {
        const newVal = oldTimer - 1;

        if (newVal === 0) {
          close();
          return oldTimer;
        }

        return newVal;
      });
    }, 1000);

    return () => clearTimeout(timer); // Очищаем таймер
  }, [timerVal]);

  return (
    <>
      <Modal close={close}>
        <SModal>
          <div>
            <CatBulbImg />
          </div>
          <p>{text}</p>
          <SIconWrapperStyled $square={true}>{timerVal}</SIconWrapperStyled>
        </SModal>
      </Modal>
    </>
  );
};

const SIconWrapperStyled = styled(SIconWrapper)`
  background-color: var(--polako-red);
  font-size: 14px;
`;

const SModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

import { useRuntimeStore } from '../../initApp';
import type { User } from '@capacitor-firebase/authentication/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { GlassfyFunctions } from '../../GlassfyFunctions';
// import { RevenueCatFunctions } from '../../RevenueCatFunctions';

export const useSetUserAndConnectBilling = () => {
  const setUser = useRuntimeStore((s) => s.setUser);

  const setUserAndConnectBilling = async (user: User | null) => {
    setUser(user);

    if (Capacitor.getPlatform() === 'ios') {
      await GlassfyFunctions.connectCustomSubscriber(user?.uid || '');
    }

    // if (Capacitor.getPlatform() === 'android') {
    //   await RevenueCatFunctions.initializeApp(user?.uid || '');
    // }
  };

  return { setUserAndConnectBilling: setUserAndConnectBilling };
};

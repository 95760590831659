import { SDaysInRow, SDaysInRowOuter } from '../../pages/Intro/styles';

export const DaysInRawText = () => {
  return (
    <SDaysInRowOuter>
      <SDaysInRow>
        вы занимаетесь <br />
        подряд уже
      </SDaysInRow>
    </SDaysInRowOuter>
  );
};

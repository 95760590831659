import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import FlowerTickSVG from '../assets/images/flower2.svg';

export const FlowerPoint: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SPoint>
      <SPointImg>
        <FlowerTickSVG />
      </SPointImg>

      <div>{children}</div>
    </SPoint>
  );
};

const SPoint = styled.div`
  display: flex;
  gap: 10px;
`;

const SPointImg = styled.div`
  color: white;
  position: relative;
  top: -2px;
`;

import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import PubSub from 'pubsub-js';
import { useLayoutEffect } from 'react';

export const useHideTabs = () => {
  useIonViewDidLeave(() => {
    PubSub.publish('showTabs');
  }, []);

  useIonViewDidEnter(() => {
    PubSub.publish('hideTabs');
  }, []);

  useLayoutEffect(() => {
    PubSub.publish('hideTabs');
  }, []);
};

import { SIconText, SIconWrapperButton } from './styles';
import { IonIcon } from '@ionic/react';
import React, { ButtonHTMLAttributes, FC } from 'react';

export type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 's' | 'sm' | 'ms' | 'm' | 'l' | undefined;
  icon?: string;
  text?: string;
  fontSize?: number;
  square?: boolean;
  wide?: boolean;
  iconWithWrapper?: React.ReactNode;
};

export const Button: FC<TButtonProps> = ({
  icon,
  text,
  color,
  size,
  fontSize,
  children,
  square = true,
  wide = false,
  iconWithWrapper,
  ...props
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SIconWrapperButton
      {...props}
      $size={size}
      $square={square}
      $color={color}
      $wide={wide}
      $fontSize={fontSize}
      fill="clear"
    >
      {icon && <IonIcon src={icon}></IonIcon>}
      {iconWithWrapper && iconWithWrapper}
      {text && (
        <SIconText $wide={wide} $fontSize={fontSize}>
          {text || children}
        </SIconText>
      )}
      {children && children}
    </SIconWrapperButton>
  );
};

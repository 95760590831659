import { H1Header } from '../../components/H1';
import { OurPhotos } from './components/OurPhotos/OurPhotos';
import styled from 'styled-components';
import { MainInfo } from './components/MainInfo/MainInfo';
import { PageWrapper } from '../../components/PageWrapper';
import { Feedback } from '../Game/features/Feedback';
import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { useIonViewDidEnter } from '@ionic/react';
import { Analytics } from '../../analytics';

export const About = () => {
  const [showFeedback, setShowFeedback] = useState(false);

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('About');
  }, []);

  return (
    <>
      <SText>
        Если хочешь оставить сообщение, просто напиши мне в телеграм{' '}
        <a href={'https://t.me/polakoapp'}>@polakoapp</a>!
        <br />
        <br />
        Или сюда –{' '}
        <ButtonStyled
          square={false}
          onClick={() => setShowFeedback(true)}
          text={'Написать'}
          size={'m'}
        />
      </SText>

      <Feedback showModal={showFeedback} setShowModal={setShowFeedback} />
      <SPart>
        <OurPhotos />
      </SPart>
      <SPart>
        <MainInfo />
      </SPart>
    </>
  );
};

const SPart = styled.div`
  padding-bottom: 50px;
  padding-top: 40px;

  &:first-child {
    padding-top: 0;
  }

  border-bottom: 3px solid var(--black, #000);
`;

const SText = styled.div`
  line-height: 1.7;
  background: white;
  padding: 20px;
  font-weight: 800;
`;

const ButtonStyled = styled(Button)`
  position: relative;
  top: -5px;
`;

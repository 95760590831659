import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthSubscriber } from './HOCs/AuthSubscriber';
import App from './App';
import React from 'react';

export function AppWrapper() {
  const resetErrorBoundary = async () => {
    await FirebaseAuthentication.removeAllListeners();
  };

  return (
    <ErrorBoundary fallbackRender={() => ''} onReset={resetErrorBoundary}>
      <AuthSubscriber>
        <App />
      </AuthSubscriber>
    </ErrorBoundary>
  );
}

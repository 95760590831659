import { Dispatch, SetStateAction, useEffect } from 'react';
import { HOW_MUCH_DEMO_LESSONS_AVAILABLE } from '../../../constants';
import { TStorageLesson } from '../types';

type TProps = {
  isAnonymous: boolean;
  lessonStat: TStorageLesson | null;
  setShowSignIn: Dispatch<SetStateAction<boolean>>;
};

export const useShowSignIn = ({
  isAnonymous,
  lessonStat,
  setShowSignIn,
}: TProps) => {
  const correct = lessonStat?.correct ?? 0;

  useEffect(() => {
    if (!isAnonymous) {
      return;
    }

    if (correct <= HOW_MUCH_DEMO_LESSONS_AVAILABLE) {
      return;
    }

    setShowSignIn(true);
  }, [lessonStat?.correct]);
};

export class CatPhrases {
  private phrases: string[];
  private shownIndices: Set<number>;

  constructor(phrases: string[]) {
    this.phrases = phrases;
    this.shownIndices = new Set();
  }

  // Возвращает случайное число в заданном диапазоне
  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Возвращает случайную фразу, которая ещё не была показана
  getRandomPhrase(): string {
    if (this.shownIndices.size === this.phrases.length) {
      this.shownIndices.clear();
    }

    let randomIndex = this.getRandomInt(0, this.phrases.length - 1);
    while (this.shownIndices.has(randomIndex)) {
      randomIndex = this.getRandomInt(0, this.phrases.length - 1);
    }

    this.shownIndices.add(randomIndex);
    return this.phrases[randomIndex];
  }

  // Функция для записи индексов показанных фраз во внешнее хранилище (просто для примера)
  saveShownIndicesToStorage(): void {
    localStorage.setItem(
      'shownIndices',
      JSON.stringify(Array.from(this.shownIndices))
    );
  }

  // Функция для чтения индексов показанных фраз из внешнего хранилища (просто для примера)
  loadShownIndicesFromStorage(): void {
    const loadedIndices = JSON.parse(
      localStorage.getItem('shownIndices') || '[]'
    );
    this.shownIndices = new Set(loadedIndices);
  }
}

import styled, { keyframes } from 'styled-components';
import CatLooksRight from '../../../../assets/images/cat-looks-right-stroke.svg';
import CatLooksLeft from '../../../../assets/images/cat-looks-left-stroke.svg';
import { TSide } from './types';

export const CatLooksRightStyled = styled(CatLooksRight)`
  width: 110px;
  height: 100%;
`;

export const CatLooksLeftStyled = styled(CatLooksLeft)`
  width: 110px;
  height: 100%;
`;

export const SText = styled.div`
  position: absolute;
  top: 42px;
  left: 54px;
  color: var(--polako-black);
  width: 144px;
  line-height: 1.3;
  font-weight: 900;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 5px;
`;

export const STextMain = styled.div`
  font-size: 14px;
`;

export const STextSub = styled.div`
  font-size: 11px;
`;

export const SDialog = styled.div<{ side: TSide }>`
  width: 260px;
  height: auto;
  top: -100px;
  color: var(--polako-black);
  font-size: 25px;
  position: absolute;

  ${({ side }) => (side === 'right' ? 'left: -170px' : 'right: -170px')};
`;

export const slideRightToLeft = keyframes`
  from {
    right: -300px;
  }
  to {
    right: -1px;
  }
`;

export const slideRightToLeftBack = keyframes`
  from {
    right: -1px;
  }
  to {
    right: -300px;
  }
`;

export const slideLeftToRight = keyframes`
  from {
    left: -300px;
  }
  to {
    left: -1px;
  }
`;

export const slideLeftToRightBack = keyframes`
  from {
    left: -1px;
  }
  to {
    left: -300px;
  }
`;

export const AnimatedDiv = styled.div<{ side: TSide }>`
  --width: 110px;

  font-size: 10px;
  position: fixed;
  bottom: 20%;
  width: var(--width);
  height: 240px;
  z-index: 1000;
  pointer-events: none;

  ${({ side }) =>
    side === 'right'
      ? `
       right: -1px; 
       left: auto;
      `
      : `
       left: -1px;  
       right: auto;
      `}

  &.fade-enter-active {
    animation: ${({ side }) =>
        side === 'right' ? slideRightToLeft : slideLeftToRight}
      500ms forwards;
  }

  &.fade-exit-active {
    animation: ${({ side }) =>
        side === 'right' ? slideRightToLeftBack : slideLeftToRightBack}
      500ms forwards;
  }
`;

import React, { FC } from 'react';
import styled from 'styled-components';

import DialogBoxTop from '../../../assets/images/dialog-box-top.svg';
import CatFirework from '../../../assets/images/cat_fireworks.svg';
import { BubbleTexts } from './BubbleTexts';

type TProps = {
  text: string;
  textSub?: string;
};

export const CatFireworkWithBubble: FC<TProps> = ({ text, textSub }) => {
  return (
    <SWrapper>
      <CatFirework />

      <SDialog>
        <STexts>
          <BubbleTexts text={text} textSub={textSub} />
        </STexts>
        <DialogBoxTop />
      </SDialog>
    </SWrapper>
  );
};

const STexts = styled.div`
  position: absolute;
  top: 1px;
  left: 4px;
`;

const SDialog = styled.div`
  width: 158px;
  height: auto;
  top: -33px;
  left: 80px;

  color: var(--polako-black);
  font-size: 25px;
  position: absolute;
`;

const SWrapper = styled.div`
  font-size: 10px;
  position: absolute;

  z-index: 1000;

  width: 290px;
  bottom: -56px;
  left: 50px;
`;

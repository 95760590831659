import { useRef } from 'react';
import { TMode, TTask } from '../types';
import { getIsOnlyGapMode } from '../utils/getIsOnlyGapMode';

const getRandomBool = () => {
  return Math.random() < 0.5;
};

const getRandomMode = (): TMode => (getRandomBool() ? 'full' : 'part');

const getMode = (task: TTask | null) => {
  if (task === null) {
    return 'part';
  }

  const justGap = getIsOnlyGapMode(task);

  if (justGap === undefined) {
    return 'full';
  }

  return justGap ? 'part' : getRandomMode();
};

export const useGetMode = (task: TTask | null) => {
  const mode = useRef<TMode>(getMode(task));

  // Если будет глючить попробовать это
  //   const [a, setA] = useState(true);

  const setRandomMode = (task: TTask) => {
    const mode1 = getMode(task);
    // Если будет глючить попробовать это
    // setA(!a);
    mode.current = mode1;
  };

  // TODO: убрать, это для теста
  // mode.current = 'full';

  return { mode, setRandomMode };
};

import { Button } from '../../../components/Button';
import { FC } from 'react';
import styled from 'styled-components';

type TMainButtonProps = {
  isCorrect: boolean | null;
  userSelectedWordsArr: string[];
  readyClickHandler: () => void;
  handleNext: () => void;
};

export const MainButton: FC<TMainButtonProps> = ({
  isCorrect,
  userSelectedWordsArr,
  readyClickHandler,
  handleNext,
}) => {
  return (
    <>
      {isCorrect === null && (
        <ButtonStyled
          disabled={userSelectedWordsArr.length === 0}
          color="yellow"
          onClick={readyClickHandler}
          size={'l'}
          square={false}
          wide
        >
          Проверить
        </ButtonStyled>
      )}
      {isCorrect === true && (
        <ButtonStyled
          color="green"
          onClick={handleNext}
          size={'l'}
          square={false}
          wide
        >
          Верно! Дальше
        </ButtonStyled>
      )}
      {isCorrect === false && (
        <ButtonStyled color="red" size={'l'} square={false} wide>
          Попробуй еще 😑
        </ButtonStyled>
      )}
    </>
  );
};

const ButtonStyled = styled(Button)`
  font-weight: 700;
`;

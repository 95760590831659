export const clearWordString = (s: string): string => {
  // Если строка начинается с заглавной буквы, делаем ее прописной
  if (s.charAt(0) === s.charAt(0).toUpperCase()) {
    s = s.charAt(0).toLowerCase() + s.slice(1);
  }

  // Очищаем строку от знаков препинания
  s = s.replace(/[.,!?;:]/g, '');

  return s;
};

import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { LessonMark } from './LessonMark';
import styled, { createGlobalStyle } from 'styled-components';
import { SIconWrapper } from '../../../components/styles';
import { BLOCK_REASONS } from '../../../constants';
import { Analytics } from '../../../analytics';
import { TBlockReason } from '../../../types';
import { useShowCatForMsNew } from '../../Game/components/EncouragingCat2/useShowCatForMsNew';
import { useRuntimeStore } from '../../../initApp';

type TProps = {
  score: number;
  link: string;
  title: string;
  subtitle: string;
  order: number;
  blockedReason: TBlockReason | undefined;
};

export const LessonName = ({
  score,
  link,
  title,
  subtitle,
  order,
  blockedReason,
}: TProps) => {
  const { showCat } = useShowCatForMsNew();
  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );

  return (
    <>
      <IonRouterLink
        routerLink={link}
        onClick={async (e) => {
          Analytics.trackLessonOpened(order);

          if (blockedReason) {
            e.preventDefault();

            if (blockedReason === 'subscription') {
              toggleSubscriptionPopup();
              return;
            }

            showCat({ text: BLOCK_REASONS[blockedReason], duration: 2500 });
          }
        }}
      >
        <GlobalButtonStyles />
        <SCard>
          <SLessonName>
            <ButtonStyled
              $square={false}
              size="m"
              $blocked={Boolean(blockedReason)}
            >
              {order}. {title}
            </ButtonStyled>
          </SLessonName>
          <SWrapper>
            <LessonMark mark={score}></LessonMark>
          </SWrapper>
        </SCard>
        <SCardDesc>{subtitle}</SCardDesc>
      </IonRouterLink>
    </>
  );
};

const GlobalButtonStyles = createGlobalStyle`
  ion-button button .button-inner {
    justify-content: flex-start;
  }
`;

const ButtonStyled = styled(SIconWrapper)<{ $blocked: boolean }>`
  width: 100%;
  min-width: 100%;
  padding: 8px 8px;
  font-size: 14px;
  justify-content: start;
  text-align: left;
  line-height: 1.3;
  height: auto;

  background-color: ${({ $blocked }) => ($blocked ? 'gray' : '#fff')};

  &:active {
    box-shadow: none;
    transform: translate(2px, 2px);
  }
`;

const SLessonName = styled.div`
  flex: 1;
`;

const SCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 6px;
`;

const SCardDesc = styled.div`
  margin-bottom: 30px;
  margin-left: 8px;
  font-size: 10px;
  font-weight: 600;
`;

const SWrapper = styled.div``;

import { IonIcon } from '@ionic/react';
import { logoApple } from 'ionicons/icons';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';

export const SignInButton = ({ iconSrc, ...props }) => {
  return (
    <ButtonStyled
      iconWithWrapper={
        iconSrc ? (
          <IconWrapper>
            <IonIcon src={iconSrc}></IonIcon>
          </IconWrapper>
        ) : null
      }
      square={false}
      {...props}
    ></ButtonStyled>
  );
};

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 22px;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;

  &::part(native) {
    height: 50px;
    width: 250px;
    font-size: 14px;
  }
`;

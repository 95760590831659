import { useRuntimeStore } from '../../initApp';
import { useAsyncFn } from 'react-use';
import { useIonViewDidEnter } from '@ionic/react';
import { useIOSSetAccessToAllPermissions } from '../../hooks/billing/useIOSSetAccessToAllPermissions';
import { Capacitor } from '@capacitor/core';
import { GlassfyFunctions } from '../../GlassfyFunctions';
import { RevenueCatFunctions } from '../../RevenueCatFunctions';
import { useAndroidBillingSetAccessToAllPermissions } from '../../hooks/billing/useAndroidBillingSetAccessToAllPermissions';

export const useAccessToAllPermissions = () => {
  const getManualSubscriptions = useRuntimeStore(
    (s) => s.getManualSubscriptions
  );
  const setAccessToAllPermission = useRuntimeStore(
    (s) => s.setAccessToAllPermission
  );

  const { setBillingAccessToAllPermissions: setAccessIOS } =
    useIOSSetAccessToAllPermissions();

  const { setBillingAccessToAllPermissions: setAccessAndroid } =
    useAndroidBillingSetAccessToAllPermissions();

  const accessToAllPermission = useRuntimeStore((s) => s.accessToAllPermission);
  const getIsSubscriptionValid = useRuntimeStore(
    (s) => s.getIsSubscriptionValid
  );

  const tryToSetBillingAccessToAllPermissions = async () => {
    if (!Capacitor.isNativePlatform()) return;

    if (Capacitor.getPlatform() === 'ios') {
      let permissions = null;
      permissions = await GlassfyFunctions.getPermissions();
      return setAccessIOS(permissions);
    }

    if (Capacitor.getPlatform() === 'android') {
      // const permissions = await RevenueCatFunctions.getPermissions();
      // console.log({ permissions });
      // return setAccessAndroid(permissions.customerInfo);
      return setAccessAndroid();
    }

    return;
  };

  const tryToSetManualAccessToAllPermissions = async () => {
    const manualSubscriptions = await getManualSubscriptions();

    if (manualSubscriptions) {
      return manualSubscriptions.some((subscription) => {
        const dateEndInMS = subscription.dateEnd * 1000;
        if (dateEndInMS > Date.now()) {
          setAccessToAllPermission({
            expireDate: dateEndInMS,
          });
          return true;
        }
        return false;
      });
    }
  };

  const [fetchPermissionsState, fetchPermissions] = useAsyncFn(async () => {
    // сначала проверям "нормальные" подписки
    if (await tryToSetBillingAccessToAllPermissions()) return;
    // затем для тех кто купил через личку
    await tryToSetManualAccessToAllPermissions();
  }, []);

  useIonViewDidEnter(() => {
    fetchPermissions();
  }, []);

  return {
    isPermissionsLoading:
      !accessToAllPermission && fetchPermissionsState.loading,
    permissionsLoadingError: fetchPermissionsState.error,
    getIsSubscriptionValid,
    accessToAllPermission,
  };
};

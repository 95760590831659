import styled from 'styled-components';

const SText = styled.div<{ $marginBottom?: string }>`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: ${(props) => props.$marginBottom || 'initial'};
`;

const SText1 = styled.div<{ $marginBottom?: string }>`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: ${(props) => props.$marginBottom || 'initial'};
`;

const STitle = styled.div<{ $marginBottom?: string }>`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: ${(props) => props.$marginBottom || 'initial'};
`;

const SHeader0 = styled.div<{ $marginBottom?: string }>`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: ${(props) => props.$marginBottom || 'initial'};
`;

const LinkButton = styled.span`
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

export const Typo = {
  Text: SText,
  Text1: SText1,
  Header1: STitle,
  Header0: SHeader0,
  LinkButton,
};
